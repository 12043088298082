import { SingleSideMenuItem } from 'shared/types/renterTypes';

export enum ResumeMenuItemsStatus {
  PENDING = 'pending',
  ERROR = 'error',
  COMPLETED = 'completed',
}
export enum SideMenuLevel {
  ONE = 1,
  TWO = 2,
}
export const ResumeStatesColors: { [key in ResumeMenuItemsStatus]: string } = {
  [ResumeMenuItemsStatus.PENDING]: '#FFD166',
  [ResumeMenuItemsStatus.ERROR]: '#EF476F',
  [ResumeMenuItemsStatus.COMPLETED]: '#60E69A',
};
export const InitialSideMenuData: SingleSideMenuItem[] = [
  {
    heading: 'Robert Uphold',
    subItems: [
      {
        heading: 'Application',
      },
      {
        heading: 'Credit & Background',
        subItems: [{ heading: 'Credit' }, { heading: 'Criminal' }, { heading: 'Housing Court' }],
      },
      {
        heading: 'Criteria & Terms',
      },
      {
        heading: 'Receipt',
      },
    ],
  },
  { heading: 'Robin Estrada', status: ResumeMenuItemsStatus.COMPLETED },
  { heading: 'Co-occupant', status: ResumeMenuItemsStatus.PENDING },
];
