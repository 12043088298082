import { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { SingleValue } from 'react-select';
import { faArrowUpRightFromSquare, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import { capitalize } from 'lodash-es';
import { selectedOrganization } from 'redux/selectors/organizationSelector';
import { isLoading, paginatedValues } from 'redux/selectors/propertySelector';
import { getPaginatedProperties, getPropertyExternalAttributes } from 'redux/slices/propertySlice';
import { AppThunkDispatch } from 'redux/store';
import { RootState } from 'redux/types';

import propertyEmptyState from 'assets/images/propertiesEmptyState.png';
import { ReactComponent as ArrowDown } from 'assets/svgs/ArrowDownWithBackgroundColor.svg';
import { ReactComponent as GridIcon } from 'assets/svgs/GridView.svg';
import { ReactComponent as ListIcon } from 'assets/svgs/ListView.svg';
import PropertyForm from 'components/Agent/Properties/AddProperty/PropertyForm';
import Button from 'components/shared/Button/Button';
import RCButton from 'components/shared/Button/Button';
import Pagination from 'components/shared/Pagination/Pagination';
import ReactSelect from 'components/shared/ReactSelect/ReactSelect';
import { mobileScreenSize } from 'constants/agentConstants';
import { PropertyStatus, StatusProps } from 'constants/dropdownOptions';
import { urlMatcher } from 'helpers/agentHelper';
import { Size, useWindowSize } from 'hooks/useResize';
import { routes } from 'shared/routes';

import EmptyPropertyIndex from './EmptyPropertyIndex';
import Property from './Property';
import PropertyGridSkeleton from './PropertyGridSkeleton';
import PropertySkeleton from './PropertySkeleton';

import styles from './PropertyIndex.module.scss';
export const MOBILE_SCREEN = 760;

export type PerPageProps = {
  value: string;
  label: string;
};

const PropertyIndex = (): JSX.Element => {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const perPage = searchParams.get('perPage') || Cookies.get('perPage') || '10';
  const currentPage = searchParams.get('page') || '1';
  const activeOption = searchParams.get('option') || 'Published';
  const activeOrganization = useSelector(selectedOrganization);
  const layout = searchParams.get('layout') || 'list';
  const dispatch = useDispatch<AppThunkDispatch>();
  const { properties, pageInfo } = useSelector((state: RootState) =>
    paginatedValues(state, activeOption.toLowerCase())
  );
  const isPageLoading = useSelector(isLoading);
  const size: Size = useWindowSize();
  const location = useLocation();
  const fetchProperties = useCallback(() => {
    if (activeOrganization.id) {
      dispatch(
        getPaginatedProperties({
          page: Number(currentPage),
          propertyStatus: activeOption.toLowerCase(),
          per_page: Number(perPage),
          organizationId: activeOrganization.id,
          isCustomAddress: false,
        })
      );
    }
  }, [dispatch, activeOrganization.id, currentPage, activeOption, perPage]);
  const handleOnChange = useCallback(
    (e: SingleValue<StatusProps>) => {
      navigate(`?option=${e?.name}&layout=${layout}`);
    },
    [navigate, layout]
  );
  const handlePageChange = (page: number): void => {
    if (perPage) {
      searchParams.set('page', `${page || 1}`);
      searchParams.set('perPage', perPage);
      setSearchParams(searchParams);
    }
  };

  useEffect(() => {
    fetchProperties();
  }, [fetchProperties]);

  const handleSkeleton = useMemo(
    (): JSX.Element => (layout === 'grid' ? <PropertyGridSkeleton /> : <PropertySkeleton />),
    [layout]
  );

  useEffect(() => {
    dispatch(getPropertyExternalAttributes());
  }, [dispatch]);
  const isPropertyMobile = useMemo((): boolean => size.width > mobileScreenSize, [size]);
  const { t } = useTranslation();
  const onClickLayout = (currentLayout: string): void => {
    searchParams.set('layout', currentLayout);

    setSearchParams(searchParams);
  };
  const handlePropertyDisplay = useMemo(
    (): JSX.Element => (
      <div className={styles.propertyDisplayDiv}>
        {!!properties?.length ? (
          <Property propertyObject={properties} />
        ) : isPropertyMobile ? (
          <Row className={styles.propertyRowLast}>
            <Col lg={7} className={styles.applicantImg}>
              <img src={propertyEmptyState} alt="Applicants List" />
            </Col>
            <Col lg={5} className={styles.statusDetails}>
              <h2 className={styles.statusDetailsH2}>
                {t('agent.properties.propertyTitle', {
                  dynamicValue: capitalize(activeOption) || 'Active',
                })}
              </h2>
              <p className={styles.statusDetailsP}>{t('agent.properties.propertyParagraph')}</p>
              <Button variant="outline" className={styles.linkedButton}>
                {t('agent.properties.learnMore')}
                <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
              </Button>
              <Button variant="outline" className={styles.addProperty} onClick={() => setIsModalVisible(true)}>
                <FontAwesomeIcon icon={faPlus} />
                {t('agent.properties.addProperty')}
              </Button>
            </Col>
          </Row>
        ) : (
          <EmptyPropertyIndex />
        )}
      </div>
    ),
    [activeOption, isPropertyMobile, properties, t]
  );

  return (
    <div className={classNames(styles.container)}>
      {urlMatcher(routes.properties, location) && !isPropertyMobile && (
        <RCButton onClick={() => setIsModalVisible(true)} className={styles.propertyButton}>
          {t('agent.properties.addPropertyPlus')}
        </RCButton>
      )}
      {isPropertyMobile && (
        <Row className={styles.propertyRow}>
          <Col lg={6} md={6} sm={12} className={styles.propertyStatus}>
            {t('agent.properties.property')} <div className={styles.verticalLine}></div>
            <ReactSelect
              hasFloatingLabel={true}
              floatingLabel={capitalize(activeOption) || t('agent.active')}
              isSearchable={false}
              options={PropertyStatus}
              placeholder=""
              isFocusedFontSize={600}
              dropDownIcon={<ArrowDown />}
              onChange={handleOnChange}
            />
            <span className={styles.propertyCount}>
              {isPageLoading ? <Skeleton height={20} width={60} /> : `${pageInfo?.totalElements ?? 0} Total`}
            </span>
          </Col>
          <Col lg={6} md={4} sm={12} className={styles.applicantButton}>
            <Button onClick={() => setIsModalVisible(true)} className={styles.dashButton}>
              {t('agent.properties.addPropertyplus')}
            </Button>
          </Col>
        </Row>
      )}
      <Row className={styles.filterRow}>
        {isPropertyMobile && (
          <Col className={styles.viewToggle}>
            <ListIcon
              data-testid="list-icon"
              onClick={() => onClickLayout('list')}
              className={classNames({ [styles.active]: layout === 'list' })}
            />
            <GridIcon
              data-testid="grid-icon"
              onClick={() => onClickLayout('grid')}
              className={classNames({ [styles.active]: layout === 'grid' })}
            />
          </Col>
        )}
      </Row>
      <div className={styles.horizontalLine}></div>
      {isPropertyMobile && <Pagination pagination={pageInfo} handlePageChange={handlePageChange} />}
      {!isPageLoading ? handlePropertyDisplay : handleSkeleton}
      <PropertyForm
        currentPage={currentPage}
        perPage={perPage}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
    </div>
  );
};

export default PropertyIndex;
