import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import { cloneDeep } from 'lodash-es';

import { handleServerResponseError, parseResponseErrors } from 'helpers/helper';
import { updateSnnName } from 'helpers/renterHelper';
import * as renterService from 'services/renterService';
import { StatusCodes } from 'shared/constants';
import { Notification } from 'shared/Notification/Notification';
import {
  ApplicationPropertyLinkProps,
  ApplicationTypeFlag,
  ConfirmApplicationProps,
} from 'shared/types/applicantsType';
import { AddCustomPropertyParams, PropertyProps } from 'shared/types/propertyType';
import { AgentDetailsResponse, PropertyAdditionalInfo } from 'shared/types/renterApplication';
import { PresentEmploymentDocumentUpload } from 'shared/types/renterIncomeInformation';
import {
  AnimalInformationProps,
  ApplicationAdditionalInfoProps,
  ErrorResponse,
  FurryFriendDeleteApi,
  FurryFriendUploadProps,
  GuarantorDispatchProps,
  PhoneNumber,
  RenterAddressInformationProps,
  RenterAdultProps,
  RenterMinorProps,
  RenterPresentEmployments,
  RenterProfileInformationInitialValue,
  RenterProfileInformationProps,
  RenterProfileProps,
  ReorderImagesProps,
  SubmitBackgroundInfoProps,
  SubmitVerificationProps,
  UpdateBackgroundInfoProps,
  UpdateRenterReportProps,
} from 'shared/types/renterTypes';
import { VehiclesInformationType } from 'shared/types/renterVehicleInformation';
import { ExperianReportsResponse, InitialExperianReportsValue } from 'shared/types/reportTypes';
import { createFormikErrorObject } from 'shared/utils/errorObject';

import { setApplicationId, setRenterId, setServerError } from './authSlice';

interface RenterInterface {
  renterId: number;
  isLoading: boolean;
  isSaving: boolean;
  renterProfileInformation: RenterProfileInformationProps;
  isDirty: boolean;
  selectedProperty: AddCustomPropertyParams;
  applicationPropertyLink: ApplicationPropertyLinkProps;
  applicationProperties: Record<number, PropertyProps>;
  selectedAgentDetails: AgentDetailsResponse;
  renterExperianReports: ExperianReportsResponse;
  propertyAdditionalInfo: PropertyAdditionalInfo;
}
export interface RenterAdultPostProps {
  applicationId: number;
  adults: RenterAdultProps;
}

export interface RenterAdultUpdateProps {
  adultId: number | undefined;
  applicationId: number;
  adults: RenterAdultProps;
}
export interface RenterMinorUpdateProps {
  minorId: number | undefined;
  applicationId: number;
  minors: RenterMinorProps;
}

export interface GetAgentDetailsProps {
  applicationId?: number;
  inviteCode?: string;
}
export interface RenterMinorPostProps {
  applicationId: number;
  minors: RenterMinorProps;
}

export const initialState: RenterInterface = {
  renterId: 0,
  isLoading: false,
  isSaving: false,
  renterProfileInformation: RenterProfileInformationInitialValue,
  isDirty: false,
  selectedProperty: {} as AddCustomPropertyParams,
  applicationPropertyLink: {} as ApplicationPropertyLinkProps,
  applicationProperties: {} as Record<number, PropertyProps>,
  selectedAgentDetails: {} as AgentDetailsResponse,
  renterExperianReports: InitialExperianReportsValue,
  propertyAdditionalInfo: {} as PropertyAdditionalInfo,
};
export interface AnimalUpdateProps {
  values: AnimalInformationProps;
  id: number | undefined;
}

export type UpdateApplicationProps = {
  values: ApplicationTypeFlag;
  applicationId: number;
};
const slice = createSlice({
  name: 'renter',
  initialState,
  reducers: {
    setIsDirty: (state, action) => {
      state.isDirty = action.payload;
    },
    setIsRenterLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setApplicationProperties: (state, action) => {
      state.applicationProperties = { ...state.applicationProperties, ...action.payload };
    },
    setAgentDetails: (state, action) => {
      const { applicationId, agentDetails } = action.payload;
      const applications = state?.renterProfileInformation?.applications;
      const application = applications.find((app) => app.id === applicationId);

      if (application) {
        application.agentDetails = agentDetails;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addRenterProfileInformation.pending, (state, payload) => {
        state.isSaving = true;

        if (payload.meta.arg.hideApiLoader) {
          state.isSaving = false;
        }
      })
      .addCase(addRenterProfileInformation.fulfilled, (state, { payload }) => {
        state.isSaving = false;
        state.renterId = payload.userId;
        state.renterProfileInformation = {
          ...state.renterProfileInformation,
          ...payload,
        };
      })
      .addCase(addRenterProfileInformation.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(createPresentEmployment.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(createPresentEmployment.fulfilled, (state, action) => {
        state.isSaving = false;
        state.renterProfileInformation.presentEmployments = action.payload.presentEmployments;
      })
      .addCase(createPresentEmployment.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(updatePresentEmployment.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(updatePresentEmployment.fulfilled, (state, action) => {
        state.isSaving = false;
        state.renterProfileInformation.presentEmployments = action.payload.presentEmployments;
      })
      .addCase(updatePresentEmployment.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(createPriorEmployment.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(createPriorEmployment.fulfilled, (state, action) => {
        state.isSaving = false;
        state.renterProfileInformation.priorEmployments = action.payload.priorEmployments;
      })
      .addCase(createPriorEmployment.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(updatePriorEmployment.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(updatePriorEmployment.fulfilled, (state, action) => {
        state.isSaving = false;
        state.renterProfileInformation.priorEmployments = action.payload.priorEmployments;
      })
      .addCase(updatePriorEmployment.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(getRenterProfileInformation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRenterProfileInformation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.renterProfileInformation = action.payload;
      })
      .addCase(getRenterProfileInformation.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(addRenterPhoneNumbers.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(addRenterPhoneNumbers.fulfilled, (state, action) => {
        state.isSaving = false;
        state.renterProfileInformation.renterPhoneNumbers = action.payload.renterPhoneNumbers;
      })
      .addCase(addRenterPhoneNumbers.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(addRenterAddressInformation.pending, (state, payload) => {
        state.isSaving = true;

        if (payload.meta.arg.hideApiLoader) {
          state.isSaving = false;
        }
      })
      .addCase(addRenterAddressInformation.fulfilled, (state, action) => {
        state.isSaving = false;
        state.renterProfileInformation.addresses = action.payload.addresses;
        state.renterId = action.payload.renterId;
      })
      .addCase(addRenterAddressInformation.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(updateRenterAddressInformation.pending, (state, payload) => {
        state.isSaving = true;

        if (payload.meta.arg.hideApiLoader) {
          state.isSaving = false;
        }
      })
      .addCase(updateRenterAddressInformation.fulfilled, (state, action) => {
        state.isSaving = false;
        state.renterProfileInformation.addresses = action.payload.addresses;
        state.renterId = action.payload.renterId;
      })
      .addCase(updateRenterAddressInformation.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(addAdultsToApplication.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(addAdultsToApplication.fulfilled, (state, { payload }) => {
        state.isSaving = false;
        const selectedApplication = state.renterProfileInformation.applications.find(
          (application) => application.id === payload.applicationId
        );

        if (selectedApplication) {
          selectedApplication.adults = payload.adults;
        }
      })
      .addCase(addAdultsToApplication.rejected, (state) => {
        state.isLoading = false;
        state.isSaving = false;
      })
      .addCase(updateAdultToApplication.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAdultToApplication.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const selectedApplication = state.renterProfileInformation.applications.find(
          (application) => application.id === payload.applicationId
        );

        if (selectedApplication) {
          selectedApplication.adults = payload.adults;
        }
      })
      .addCase(updateAdultToApplication.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(addMinorsToApplication.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addMinorsToApplication.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const selectedApplication = state.renterProfileInformation.applications.find(
          (application) => application.id === payload.applicationId
        );

        if (selectedApplication) {
          selectedApplication.minors = payload.minors;
        }
      })
      .addCase(addMinorsToApplication.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(updateMinorToApplication.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateMinorToApplication.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const selectedApplication = state.renterProfileInformation.applications.find(
          (application) => application.id === payload.applicationId
        );

        if (selectedApplication) {
          selectedApplication.minors = payload.minors;
        }
      })
      .addCase(updateMinorToApplication.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(addVehicleInformation.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(addVehicleInformation.fulfilled, (state, action) => {
        state.isSaving = false;
        state.renterProfileInformation.vehicles = action.payload.vehicles;
      })
      .addCase(addVehicleInformation.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(addFurryFriendToRenter.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addFurryFriendToRenter.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.renterProfileInformation.furryFriends = payload.furryFriends;
      })
      .addCase(addFurryFriendToRenter.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteFurryFriend.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteFurryFriend.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.renterProfileInformation.furryFriends = payload.furryFriends;
      })
      .addCase(deleteFurryFriend.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(updateFurryFriendToRenter.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateFurryFriendToRenter.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.renterProfileInformation.furryFriends = payload.furryFriends;
      })
      .addCase(updateFurryFriendToRenter.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(addRenterPayment.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(addRenterPayment.fulfilled, (state) => {
        state.isSaving = false;
      })
      .addCase(addRenterPayment.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(addRenterGuarantorInformation.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(getSelectedProperty.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selectedProperty = action.payload;
      })
      .addCase(getSelectedProperty.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getSelectedProperty.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addRenterGuarantorInformation.fulfilled, (state, action) => {
        const selectedApplication = state.renterProfileInformation.applications.find(
          (application) => application.id === action.payload.applicationId
        );

        if (selectedApplication) {
          selectedApplication.guarantor = action.payload.guarantor;
        }

        state.isSaving = false;
      })
      .addCase(addRenterGuarantorInformation.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(updateRenterGuarantorInformation.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(updateRenterGuarantorInformation.fulfilled, (state, action) => {
        const selectedApplication = state.renterProfileInformation.applications.find(
          (application) => application.id === action.payload.applicationId
        );

        if (selectedApplication) {
          selectedApplication.guarantor = action.payload.guarantor;
        }

        state.isSaving = false;
      })
      .addCase(updateRenterGuarantorInformation.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(uploadPresentEmploymentDocuments.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(uploadPresentEmploymentDocuments.fulfilled, (state, action) => {
        const selectedPresentEmployment = state.renterProfileInformation.presentEmployments.find(
          (presentEmployment) => presentEmployment.id === action.payload.presentEmploymentId
        );

        if (selectedPresentEmployment) {
          selectedPresentEmployment.documents = action.payload.documents;
        }

        state.isSaving = false;
      })
      .addCase(uploadPresentEmploymentDocuments.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(deletePresentEmploymentDocuments.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(deletePresentEmploymentDocuments.fulfilled, (state, action) => {
        const selectedPresentEmployment = state.renterProfileInformation.presentEmployments.findIndex(
          (presentEmployment) => presentEmployment.id === action.payload.presentEmploymentId
        );

        if (selectedPresentEmployment !== -1) {
          state.renterProfileInformation.presentEmployments[selectedPresentEmployment].documents =
            action.payload.documents;
        }

        state.isSaving = false;
      })
      .addCase(deletePresentEmploymentDocuments.rejected, (state) => {
        state.isSaving = false;
      })

      .addCase(uploadFurryFriendsImages.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(uploadFurryFriendsImages.fulfilled, (state, action) => {
        const info = cloneDeep(state.renterProfileInformation);
        const selectedFurryFriend = info.furryFriends.find(
          (furryFriend) => furryFriend.id === action.payload.furryFriendId
        );

        if (selectedFurryFriend) {
          selectedFurryFriend.images = action.payload.images;
        }

        state.renterProfileInformation = info;

        state.isSaving = false;
      })
      .addCase(uploadFurryFriendsImages.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(deleteFurryFriendsImage.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(deleteFurryFriendsImage.fulfilled, (state, action) => {
        const selectedFurryFriend = state.renterProfileInformation.furryFriends.find(
          (furryFriend) => furryFriend.id === action.payload.furryFriendId
        );

        if (selectedFurryFriend) {
          selectedFurryFriend.images = action.payload.images;
        }

        state.isSaving = false;
      })
      .addCase(deleteFurryFriendsImage.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(uploadFurryFriendsDocuments.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(uploadFurryFriendsDocuments.fulfilled, (state, action) => {
        const selectedFurryFriend = state.renterProfileInformation.furryFriends.find(
          (furryFriend) => furryFriend.id === action.payload.furryFriendId
        );

        if (selectedFurryFriend) {
          selectedFurryFriend.documents = action.payload.documents;
        }

        state.isSaving = false;
      })
      .addCase(uploadFurryFriendsDocuments.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(deleteFurryFriendsDocument.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(deleteFurryFriendsDocument.fulfilled, (state, action) => {
        const selectedFurryFriend = state.renterProfileInformation.furryFriends.find(
          (furryFriend) => furryFriend.id === action.payload.furryFriendId
        );

        if (selectedFurryFriend) {
          selectedFurryFriend.documents = action.payload.documents;
        }

        state.isSaving = false;
      })
      .addCase(deleteFurryFriendsDocument.rejected, (state) => {
        state.isSaving = false;
      })

      .addCase(reorderRenterFurryFriendsImages.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(reorderRenterFurryFriendsImages.fulfilled, (state, action) => {
        const selectedFurryFriend = state.renterProfileInformation.furryFriends.find(
          (furryFriend) => furryFriend.id === action.payload.furryFriendId
        );

        if (selectedFurryFriend) {
          selectedFurryFriend.images = action.payload.images;
        }

        state.isSaving = false;
      })
      .addCase(signTermsOfUse.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(signTermsOfUse.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(signTermsOfUse.fulfilled, (state) => {
        state.renterProfileInformation.signedTermsOfUse = true;

        state.isSaving = false;
      })
      .addCase(reorderRenterFurryFriendsImages.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(getAddressHistoryQuestionnaire.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(getAddressHistoryQuestionnaire.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAddressHistoryQuestionnaire.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(createVerificationInformation.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(createVerificationInformation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createVerificationInformation.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(getApplicationPropertyLink.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getApplicationPropertyLink.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getApplicationPropertyLink.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.applicationPropertyLink = payload?.applicationProperties[0];
      })
      .addCase(confirmRenterApplication.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(confirmRenterApplication.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(confirmRenterApplication.fulfilled, (state) => {
        state.isSaving = false;
      })
      .addCase(getBackgroundQuestionnaire.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getBackgroundQuestionnaire.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBackgroundQuestionnaire.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(addBackgroundInformation.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(addBackgroundInformation.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(addBackgroundInformation.fulfilled, (state, action) => {
        state.isSaving = false;
        const currentApplication = state.renterProfileInformation.applications.find(
          (application) => application.id === action.payload.applicationId
        );

        if (currentApplication) {
          currentApplication.backgroundInformation = action.payload.backgroundInformation;
        }
      })
      .addCase(updateBackgroundInformation.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(updateBackgroundInformation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateBackgroundInformation.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(getAgentDetails.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getAgentDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAgentDetails.fulfilled, (state, { payload }) => {
        state.selectedAgentDetails = payload;
        state.isLoading = false;
      })
      .addCase(getAdditionalApplicationInfo.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getAdditionalApplicationInfo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAdditionalApplicationInfo.fulfilled, (state, { payload }) => {
        state.propertyAdditionalInfo = payload;
        state.isLoading = false;
      })
      .addCase(updateRenterReportConsent.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(updateRenterReportConsent.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(updateRenterReportConsent.fulfilled, (state, payload) => {
        state.isSaving = false;
        const currentApplication = state.renterProfileInformation.applications.find(
          (application) => application.id === payload.meta.arg.applicationId
        );

        if (currentApplication) {
          currentApplication.reportConsent = payload.meta.arg.isConsented;
        }
      })
      .addCase(getRenterExperianReportInformation.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getRenterExperianReportInformation.pending, (state) => {
        state.isLoading = true;
        state.renterExperianReports = InitialExperianReportsValue;
      })
      .addCase(getRenterExperianReportInformation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.renterExperianReports = action.payload;
      })

      .addCase(updateApplication.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(updateApplication.fulfilled, (state, { payload }) => {
        state.isSaving = false;
        const applications = state.renterProfileInformation.applications;
        const indexToUpdate = applications.findIndex((application) => application.id === payload.id);

        applications[indexToUpdate] = payload;
      })
      .addCase(updateApplication.rejected, (state) => {
        state.isSaving = false;
      })
      .addDefaultCase((state) => state);
  },
});

type RenterProfileParams = {
  values: RenterProfileProps;
  shouldSnnChange?: boolean;
  hideApiLoader?: boolean;
};
type RenterAddAddressParms = {
  address: RenterAddressInformationProps;
  hideApiLoader?: boolean;
};
type RenterUpdateAddressParms = {
  id: number;
  hideApiLoader?: boolean;
  address: RenterAddressInformationProps;
};
type RenterDeleteAddressParms = {
  id: string | number;
};
export const addRenterProfileInformation = createAsyncThunk(
  'renter/profileInformation',
  async ({ values, shouldSnnChange }: RenterProfileParams, { rejectWithValue, dispatch }) => {
    try {
      let updatedValues = values;

      if (shouldSnnChange) {
        updatedValues = updateSnnName(values);
      }

      if (updatedValues.id) {
        const {
          data: { payload },
        }: AxiosResponse = await renterService.updateProfile(updatedValues);

        return payload;
      }

      const {
        data: { payload },
      }: AxiosResponse = await renterService.createProfile(updatedValues);

      dispatch(setRenterId(payload.userId));

      return payload;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        const errorObject = createFormikErrorObject(error as AxiosError);
        const status = error.response.status;

        dispatch(setServerError(status));

        return rejectWithValue({ ...errorObject });
      }

      return Promise.reject();
    }
  }
);

// Create a separate async action to handle profile errors
export const handleProfileError = createAsyncThunk<void, AxiosError<ErrorResponse>>(
  'renter/handleProfileError',
  async (e, { dispatch }) => {
    if (e instanceof AxiosError && e.response && e.response.status) {
      const errorCode = e.response.data.errors?.[0].errorCode;

      if (errorCode !== 161 && !!e.response.data.errors) {
        Notification({ message: e.response.data.errors[0].errorMessage });
      }

      // If getProfileInformation fails, attempt to create the profile information
      try {
        // Create the renter profile
        const {
          data: {
            payload: { userId },
          },
        }: AxiosResponse = await renterService.createProfile({});

        // Dispatch the renter ID
        dispatch(setRenterId(userId));

        // Fetch the updated renter profile information
        const {
          data: { payload },
        }: AxiosResponse = await renterService.getRenterProfileInformation();

        // Return the updated payload
        return payload;
      } catch (error) {
        // Handle any errors that occur during the creation or subsequent getProfileInformation attempt
        handleServerResponseError({ error, dispatch });

        return Promise.reject();
      }
    }

    return Promise.reject();
  }
);

// Use handleProfileError action inside getRenterProfileInformation's catch block
export const getRenterProfileInformation = createAsyncThunk('renter/getProfileInformation', async (_, { dispatch }) => {
  try {
    const {
      data: { payload },
    }: AxiosResponse = await renterService.getRenterProfileInformation();

    dispatch(setRenterId(payload.userId));
    dispatch(setApplicationId(payload?.applications?.[0]?.id));

    return payload;
  } catch (e) {
    // Handle errors using the handleProfileError action
    try {
      if (e instanceof AxiosError && e.response && e.response.status === StatusCodes.INTERNAL_SERVER_ERROR) {
        throw e;
      }

      const { payload } = await dispatch(handleProfileError(e as AxiosError<ErrorResponse>));

      return payload;
    } catch (error) {
      // Handle any errors that occur during the creation or subsequent getProfileInformation attempt
      handleServerResponseError({ error, dispatch });

      return Promise.reject();
    }
  }
});

type RenterPresentEmploymentsParams = {
  values: RenterPresentEmployments;
};

export const createPresentEmployment = createAsyncThunk(
  'renter/presentEmployments',
  async ({ values }: RenterPresentEmploymentsParams, { rejectWithValue, dispatch }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await renterService.createPresentEmployment(values);

      return payload;
    } catch (e) {
      Notification({ message: parseResponseErrors(e) });

      if (e instanceof AxiosError && e.response) {
        const status = (e as AxiosError)?.response?.status;

        dispatch(setServerError(status));
        const errorObject = createFormikErrorObject(e as AxiosError);

        return rejectWithValue({ ...errorObject });
      }

      return Promise.reject();
    }
  }
);
export const createPriorEmployment = createAsyncThunk(
  'renter/priorEmployments',
  async ({ values }: RenterPresentEmploymentsParams, { rejectWithValue, dispatch }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await renterService.createPriorEmployment(values);

      return payload;
    } catch (e) {
      if (e instanceof AxiosError && e.response) {
        const status = (e as AxiosError)?.response?.status;

        dispatch(setServerError(status));
        const errorObject = createFormikErrorObject(e as AxiosError);

        return rejectWithValue({ ...errorObject });
      }

      return Promise.reject();
    }
  }
);

type RenterPriorEmploymentsParams = {
  values: RenterPresentEmployments;
  id: number | string;
};

export const updatePriorEmployment = createAsyncThunk(
  'renter/priorEmploymentsUpdate',
  async ({ values, id }: RenterPriorEmploymentsParams, { rejectWithValue, dispatch }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await renterService.updatePriorEmployment(values, id);

      return payload;
    } catch (e) {
      if (e instanceof AxiosError && e.response) {
        const status = (e as AxiosError)?.response?.status;

        dispatch(setServerError(status));
        const errorObject = createFormikErrorObject(e as AxiosError);

        return rejectWithValue({ ...errorObject });
      }

      return Promise.reject();
    }
  }
);
export const updatePresentEmployment = createAsyncThunk(
  'renter/presentEmploymentsUpdate',
  async ({ values, id }: RenterPriorEmploymentsParams, { rejectWithValue, dispatch }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await renterService.updatePresentEmployment(values, id);

      return payload;
    } catch (e) {
      const status = (e as AxiosError)?.response?.status;

      dispatch(setServerError(status));

      if (e instanceof AxiosError && e.response) {
        const errorObject = createFormikErrorObject(e as AxiosError);

        return rejectWithValue({ ...errorObject });
      }

      return Promise.reject();
    }
  }
);
export const addRenterAddressInformation = createAsyncThunk(
  'renter/addAddressInformation',
  async ({ address }: RenterAddAddressParms, { rejectWithValue, dispatch }) => {
    try {
      if (address) {
        const {
          data: { payload },
        }: AxiosResponse = await renterService.createRenterAddress(address);

        return payload;
      }
    } catch (e) {
      if (e instanceof AxiosError && e.response) {
        const status = (e as AxiosError)?.response?.status;

        dispatch(setServerError(status));
        const errorObject = createFormikErrorObject(e as AxiosError);

        return rejectWithValue({ ...errorObject });
      }

      return Promise.reject();
    }
  }
);

export const updateRenterAddressInformation = createAsyncThunk(
  'renter/updateAddressInformation',
  async ({ id, address }: RenterUpdateAddressParms, { rejectWithValue, dispatch }) => {
    try {
      if (address) {
        const {
          data: { payload },
        }: AxiosResponse = await renterService.updateRenterAddress(id, address);

        return payload;
      }
    } catch (e) {
      if (e instanceof AxiosError && e.response) {
        Notification({ message: parseResponseErrors(e) });

        const status = (e as AxiosError)?.response?.status;

        dispatch(setServerError(status));
        const errorObject = createFormikErrorObject(e as AxiosError);

        return rejectWithValue({ ...errorObject });
      }

      return Promise.reject();
    }
  }
);
export const addAdultsToApplication = createAsyncThunk(
  'renter/addAdultsToApplication',
  async ({ applicationId, adults }: RenterAdultPostProps, { rejectWithValue, dispatch }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await renterService.createAdult(applicationId, adults);

      return payload;
    } catch (e) {
      const status = (e as AxiosError)?.response?.status;

      dispatch(setServerError(status));
      const errorObject = createFormikErrorObject(e as AxiosError);

      return rejectWithValue({ ...errorObject });
    }
  }
);

export const updateAdultToApplication = createAsyncThunk(
  'renter/updateAdultToApplication',
  async ({ applicationId, adults, adultId }: RenterAdultUpdateProps, { rejectWithValue, dispatch }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await renterService.updateAdult(applicationId, adultId, adults);

      return payload;
    } catch (e) {
      const status = (e as AxiosError)?.response?.status;

      dispatch(setServerError(status));
      const errorObject = createFormikErrorObject(e as AxiosError);

      return rejectWithValue({ ...errorObject });
    }
  }
);

type RenterPhoneNumberParams = {
  values: PhoneNumber;
};

export const addRenterPhoneNumbers = createAsyncThunk(
  'renter/phoneNumber',
  async ({ values }: RenterPhoneNumberParams, { rejectWithValue, dispatch }) => {
    try {
      if (values.id) {
        const {
          data: { payload },
        }: AxiosResponse = await renterService.updateRenterPhoneNumber(values);

        return payload;
      }

      const {
        data: { payload },
      }: AxiosResponse = await renterService.createRenterPhoneNumber(values);

      return payload;
    } catch (e) {
      if (e instanceof AxiosError && e.response) {
        const status = (e as AxiosError)?.response?.status;

        dispatch(setServerError(status));

        return rejectWithValue(e.response.data.errors[0]);
      }

      return Promise.reject();
    }
  }
);

export const deleteRenterPhoneNumber = createAsyncThunk(
  'renter/phoneNumber',
  async (id: number, { rejectWithValue, dispatch }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await renterService.deleteRenterPhoneNumber(id);

      return payload;
    } catch (e) {
      if (e instanceof AxiosError && e.response) {
        const status = (e as AxiosError)?.response?.status;

        dispatch(setServerError(status));

        return rejectWithValue(e.response.data.errors[0]);
      }

      return Promise.reject();
    }
  }
);

export const deleteRenterAddressInformation = createAsyncThunk(
  'renter/deleteAddressInformation',
  async ({ id }: RenterDeleteAddressParms, { rejectWithValue, dispatch }) => {
    try {
      if (id) {
        const {
          data: { payload },
        }: AxiosResponse = await renterService.deleteRenterAddress(id);

        return payload;
      }
    } catch (e) {
      if (e instanceof AxiosError && e.response) {
        const status = (e as AxiosError)?.response?.status;

        dispatch(setServerError(status));
        const errorObject = createFormikErrorObject(e as AxiosError);

        return rejectWithValue({ ...errorObject });
      }

      return Promise.reject();
    }
  }
);

export const addMinorsToApplication = createAsyncThunk(
  'renter/addMinorsToApplication',
  async ({ applicationId, minors }: RenterMinorPostProps, { rejectWithValue, dispatch }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await renterService.createMinor(applicationId, minors);

      return payload;
    } catch (e) {
      const status = (e as AxiosError)?.response?.status;

      Notification({ message: parseResponseErrors(e) });

      dispatch(setServerError(status));
      const errorObject = createFormikErrorObject(e as AxiosError);

      return rejectWithValue({ ...errorObject });
    }
  }
);

export const updateMinorToApplication = createAsyncThunk(
  'renter/updateMinorToApplication',
  async ({ applicationId, minors, minorId }: RenterMinorUpdateProps, { rejectWithValue, dispatch }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await renterService.updateMinor(applicationId, minorId, minors);

      return payload;
    } catch (e) {
      const status = (e as AxiosError)?.response?.status;

      dispatch(setServerError(status));
      const errorObject = createFormikErrorObject(e as AxiosError);

      return rejectWithValue({ ...errorObject });
    }
  }
);

type RenterVehicleParams = {
  values: VehiclesInformationType;
  id: number | string;
};
export const addVehicleInformation = createAsyncThunk(
  'renter/vehicles',
  async ({ values }: RenterVehicleParams, { rejectWithValue, dispatch }) => {
    try {
      if (values.id) {
        const {
          data: { payload },
        }: AxiosResponse = await renterService.updateVehicle(values);

        return payload;
      }

      const {
        data: { payload },
      }: AxiosResponse = await renterService.createVehicle(values);

      return payload;
    } catch (e) {
      if (e instanceof AxiosError && e.response) {
        const status = (e as AxiosError)?.response?.status;

        dispatch(setServerError(status));
        const errorObject = createFormikErrorObject(e as AxiosError);

        return rejectWithValue({ ...errorObject });
      }

      return Promise.reject();
    }
  }
);
export const addFurryFriendToRenter = createAsyncThunk(
  'renter/addFurryFriendToRenter',
  async (values: AnimalInformationProps, { rejectWithValue, dispatch }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await renterService.createFurryFriends(values);

      return payload;
    } catch (e) {
      const status = (e as AxiosError)?.response?.status;

      dispatch(setServerError(status));
      const errorObject = createFormikErrorObject(e as AxiosError);

      return rejectWithValue({ ...errorObject });
    }
  }
);
export const updateFurryFriendToRenter = createAsyncThunk(
  'renter/updateFurryFriendToRenter',
  async ({ id, values }: AnimalUpdateProps, { rejectWithValue, dispatch }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await renterService.updateFurryFriends(id, values);

      return payload;
    } catch (e) {
      const status = (e as AxiosError)?.response?.status;

      dispatch(setServerError(status));
      const errorObject = createFormikErrorObject(e as AxiosError);

      return rejectWithValue({ ...errorObject });
    }
  }
);
export type RenterPaymentParams = {
  amount: number;
  propertyAddress: string;
  organizationName: string;
  applicationId: number;
};
export const addRenterPayment = createAsyncThunk(
  'renter/paymentInformation',
  async ({ amount, propertyAddress, organizationName, applicationId }: RenterPaymentParams, { rejectWithValue }) => {
    try {
      if (amount) {
        const {
          data: { payload },
        }: AxiosResponse = await renterService.createRenterPayment({
          amount,
          propertyAddress,
          organizationName,
          applicationId,
        });

        return payload;
      }
    } catch (e) {
      if (e instanceof AxiosError && e.response) {
        const errorObject = createFormikErrorObject(e as AxiosError);

        return rejectWithValue({ ...errorObject });
      }

      return Promise.reject();
    }
  }
);

export const uploadPresentEmploymentDocuments = createAsyncThunk(
  'presentEmployment/documentUpload',
  async (
    { presentEmploymentId, document, documentSubType, documentType }: PresentEmploymentDocumentUpload,
    { dispatch }
  ) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await renterService.presentEmploymentDocumentUpload({
        presentEmploymentId,
        document,
        documentType,
        documentSubType,
      });

      return payload;
    } catch (error) {
      handleServerResponseError({ error, dispatch });

      return Promise.reject();
    }
  }
);
export const deletePresentEmploymentDocuments = createAsyncThunk(
  'presentEmployment/documentDelete',
  async ({ presentEmploymentId, document }: PresentEmploymentDocumentUpload, { dispatch }) => {
    try {
      const documentId = document.id;

      if (documentId) {
        const {
          data: { payload },
        }: AxiosResponse = await renterService.deletePresentEmploymentDocument({ presentEmploymentId, documentId });

        return payload;
      }
    } catch (error) {
      handleServerResponseError({ error, dispatch });

      return Promise.reject();
    }
  }
);
export const uploadFurryFriendsImages = createAsyncThunk(
  'furryFriends/imagesUpload',
  async ({ furryFriendId, document }: FurryFriendUploadProps, { dispatch }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await renterService.furryFriendImagesUpload({ furryFriendId, document });

      return payload;
    } catch (error) {
      handleServerResponseError({ error, dispatch });

      return Promise.reject();
    }
  }
);
export const uploadFurryFriendsDocuments = createAsyncThunk(
  'furryFriends/documentsUpload',
  async ({ furryFriendId, document }: FurryFriendUploadProps, { dispatch }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await renterService.furryFriendDocumentUpload({ furryFriendId, document });

      return payload;
    } catch (error) {
      handleServerResponseError({ error, dispatch });

      return Promise.reject();
    }
  }
);
export const deleteFurryFriendsImage = createAsyncThunk(
  'furryFriends/imageDelete',
  async ({ furryFriendId, documentId }: FurryFriendDeleteApi, { dispatch }) => {
    try {
      if (documentId) {
        const {
          data: { payload },
        }: AxiosResponse = await renterService.deleteFurryFriendImage({ furryFriendId, documentId });

        return payload;
      }
    } catch (error) {
      handleServerResponseError({ error, dispatch });

      return Promise.reject();
    }
  }
);
export type GetSelectedPropertyType = {
  propertyId: number;
};
export const getSelectedProperty = createAsyncThunk(
  'property/getSelectedProperty',
  async ({ propertyId }: GetSelectedPropertyType, { dispatch }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await renterService.getSelectedProperty(propertyId);

      return payload;
    } catch (error) {
      handleServerResponseError({ error, dispatch });

      return Promise.reject();
    }
  }
);
export const deleteFurryFriendsDocument = createAsyncThunk(
  'furryFriends/documentDelete',
  async ({ furryFriendId, documentId }: FurryFriendDeleteApi, { dispatch }) => {
    try {
      if (documentId) {
        const {
          data: { payload },
        }: AxiosResponse = await renterService.deleteFurryFriendDocument({ furryFriendId, documentId });

        return payload;
      }
    } catch (error) {
      handleServerResponseError({ error, dispatch });

      return Promise.reject();
    }
  }
);
export type RenterGuarantorParams = {
  values: GuarantorDispatchProps;
  applicationId: number;
};
export const addRenterGuarantorInformation = createAsyncThunk(
  'renter/addGuarantorInformation',
  async ({ applicationId, values }: RenterGuarantorParams, { rejectWithValue, dispatch }) => {
    try {
      if (values && applicationId) {
        const {
          data: { payload },
        }: AxiosResponse = await renterService.createRenterGuarantor(applicationId, values);

        return payload;
      }
    } catch (e) {
      if (e instanceof AxiosError && e.response) {
        const status = (e as AxiosError)?.response?.status;

        dispatch(setServerError(status));
        const errorObject = createFormikErrorObject(e as AxiosError);

        return rejectWithValue({ ...errorObject });
      }

      return Promise.reject();
    }
  }
);
export type RenterUpdateGuarantorParams = {
  applicationId: number;
  id: number | string;
  values: GuarantorDispatchProps;
};
export const updateRenterGuarantorInformation = createAsyncThunk(
  'renter/updateGuarantorInformation',
  async ({ id, values, applicationId }: RenterUpdateGuarantorParams, { rejectWithValue, dispatch }) => {
    try {
      if (id && values && applicationId) {
        const {
          data: { payload },
        }: AxiosResponse = await renterService.updateRenterGuarantor(id, applicationId, values);

        return payload;
      }
    } catch (e) {
      if (e instanceof AxiosError && e.response) {
        const status = (e as AxiosError)?.response?.status;

        dispatch(setServerError(status));
        const errorObject = createFormikErrorObject(e as AxiosError);

        return rejectWithValue({ ...errorObject });
      }

      return Promise.reject();
    }
  }
);
export const reorderRenterFurryFriendsImages = createAsyncThunk(
  'renter/reorderRenterFurryFriendsImages',
  async ({ furryFriendId, imageIds }: ReorderImagesProps, { dispatch }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await renterService.renterReorderAnimalImages({ furryFriendId, imageIds });

      return payload;
    } catch (e) {
      if (e instanceof AxiosError && e.response) {
        const status = (e as AxiosError)?.response?.status;

        dispatch(setServerError(status));
      }

      return Promise.reject();
    }
  }
);
export const signTermsOfUse = createAsyncThunk('renter/termsOfUse', async (_, { dispatch }) => {
  try {
    const {
      data: { payload },
    }: AxiosResponse = await renterService.signTermsOfUse();

    return payload;
  } catch (e) {
    if (e instanceof AxiosError && e.response) {
      const status = (e as AxiosError)?.response?.status;

      dispatch(setServerError(status));
    }

    return Promise.reject();
  }
});

export const getAddressHistoryQuestionnaire = createAsyncThunk(
  'verifiers/questions',
  async (token: string, { dispatch }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await renterService.getAddressHistoryQuestionnaire(token);

      return payload;
    } catch (error) {
      handleServerResponseError({ error, dispatch });

      return Promise.reject();
    }
  }
);

export const createVerificationInformation = createAsyncThunk(
  'address-verification',
  async ({ token, values, acceptedFiles }: SubmitVerificationProps, { dispatch, rejectWithValue }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await renterService.createVerificationInformation(token, values, acceptedFiles);

      return payload;
    } catch (error) {
      handleServerResponseError({ error, dispatch });

      if (error instanceof AxiosError && error.response) {
        const errorObject = createFormikErrorObject(error as AxiosError);

        return rejectWithValue({ ...errorObject });
      }
    }
  }
);

export const getApplicationPropertyLink = createAsyncThunk(
  'renter/getApplicationPropertyLink',
  async (applicationId: number, { dispatch }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await renterService.getApplicationPropertyLinks(applicationId);

      return payload;
    } catch (error) {
      handleServerResponseError({ error, dispatch });

      if (error instanceof AxiosError && error.response) {
        Notification({ message: parseResponseErrors(error) });
      }

      return Promise.reject();
    }
  }
);
export const confirmRenterApplication = createAsyncThunk(
  'renter/confirmRenterApplication',
  async ({ applicationId, body }: ConfirmApplicationProps, { dispatch }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await renterService.confirmRenterApplication(applicationId, body);

      return payload;
    } catch (error) {
      handleServerResponseError({ error, dispatch });

      if (error instanceof AxiosError && error.response) {
        Notification({
          message: `${parseResponseErrors(error)}`,
        });
      }

      return Promise.reject();
    }
  }
);
export const getBackgroundQuestionnaire = createAsyncThunk('renter/getBackgroundQuestions', async (_, { dispatch }) => {
  try {
    const {
      data: { payload },
    }: AxiosResponse = await renterService.getBackgroundQuestionnaire();

    return payload;
  } catch (error) {
    handleServerResponseError({ error, dispatch });

    return Promise.reject();
  }
});
export const addBackgroundInformation = createAsyncThunk(
  'renter/addBackgroundInformation',
  async ({ applicationId, submitBackgroundInformation }: SubmitBackgroundInfoProps, { dispatch, rejectWithValue }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await renterService.addBackgroundInformation(applicationId, submitBackgroundInformation);

      return payload;
    } catch (error) {
      handleServerResponseError({ error, dispatch });

      if (error instanceof AxiosError && error.response) {
        const errorObject = createFormikErrorObject(error as AxiosError);

        return rejectWithValue({ ...errorObject });
      }
    }
  }
);

export const updateBackgroundInformation = createAsyncThunk(
  'update-background-information',
  async (
    { applicationId, backgroundInfoId, backgroundInfo }: UpdateBackgroundInfoProps,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await renterService.updateBackgroundInformation(
        applicationId,
        backgroundInfoId,
        backgroundInfo
      );

      return payload;
    } catch (error) {
      handleServerResponseError({ error, dispatch });

      if (error instanceof AxiosError && error.response) {
        const errorObject = createFormikErrorObject(error as AxiosError);

        return rejectWithValue({ ...errorObject });
      }
    }
  }
);

export const getAgentDetails = createAsyncThunk(
  'application/agentDetails',
  async ({ applicationId, inviteCode }: GetAgentDetailsProps, { dispatch }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await renterService.getAgentDetails(applicationId, inviteCode);

      return payload;
    } catch (error) {
      handleServerResponseError({ error, dispatch });

      return Promise.reject();
    }
  }
);

export const getAdditionalApplicationInfo = createAsyncThunk(
  'application/additionalInformation',
  async ({ organizationId, propertyId, applicationId }: ApplicationAdditionalInfoProps, { dispatch }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await renterService.getAdditionalApplicationInfo(organizationId, propertyId, applicationId);

      return payload;
    } catch (error) {
      handleServerResponseError({ error, dispatch });

      return Promise.reject();
    }
  }
);
export const updateRenterReportConsent = createAsyncThunk(
  'update-report-consent',
  async ({ applicationId, isConsented }: UpdateRenterReportProps, { dispatch, rejectWithValue }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await renterService.updateRenterReportConsent(applicationId, isConsented);

      return payload;
    } catch (error) {
      handleServerResponseError({ error, dispatch });

      if (error instanceof AxiosError && error.response) {
        const errorObject = createFormikErrorObject(error as AxiosError);

        return rejectWithValue({ ...errorObject });
      }
    }
  }
);
export const getRenterExperianReportInformation = createAsyncThunk(
  'renter/getExperianReportInformation',
  async (_, { dispatch }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await renterService.getRenterExperianReport();

      return payload;
    } catch (error) {
      handleServerResponseError({ error, dispatch });

      if (error instanceof AxiosError && error.response) {
        Notification({ message: parseResponseErrors(error) });

        return Promise.reject(error.response.data.errors[0].errorCode); //error code to check the report expirey status
      }
    }
  }
);
export const updateApplication = createAsyncThunk(
  'renter/updateApplication',
  async ({ values, applicationId }: UpdateApplicationProps, { dispatch }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await renterService.updateApplication(values, applicationId);

      return payload;
    } catch (error) {
      handleServerResponseError({ error, dispatch });

      if (error instanceof AxiosError && error.response) {
        Notification({ message: parseResponseErrors(error) });

        return Promise.reject(error.response.data.errors[0].errorCode); //error code to check the report expirey status
      }
    }
  }
);

export const getOrganizationName = createAsyncThunk(
  'renter/organizationName',
  async ({ propertyId }: { propertyId: number }, { dispatch }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await renterService.getOrganizationName(propertyId);

      return payload;
    } catch (error) {
      handleServerResponseError({ error, dispatch });

      if (error instanceof AxiosError && error.response) {
        Notification({ message: parseResponseErrors(error) });

        return Promise.reject(error.response.data.errors[0].errorCode); //error code to check the report expirey status
      }
    }
  }
);
export const deleteFurryFriend = createAsyncThunk(
  'renter/deleteFurryFriend',
  async ({ animalId }: { animalId: number }, { rejectWithValue, dispatch }) => {
    try {
      if (animalId) {
        const {
          data: { payload },
        }: AxiosResponse = await renterService.deleteFurryFriend({ furryFriendId: animalId });

        return payload;
      }
    } catch (error) {
      handleServerResponseError({ error, dispatch });

      if (error instanceof AxiosError && error.response) {
        Notification({ message: parseResponseErrors(error) });

        const errorObject = createFormikErrorObject(error as AxiosError);

        return rejectWithValue({ ...errorObject });
      }
    }
  }
);
export const { setIsDirty, setIsRenterLoading, setApplicationProperties, setAgentDetails } = slice.actions;

export const { reducer } = slice;
