export const leagcyList = [
  {
    submitted: 'Jul 08, 2024',
    approved: 'Jul 08, 2024',
    property: '445 N Oakhurst Dr Beverly Hills, CA, 90210',
    unit: '106',
    name: 'Cesar Alejandro Hernandez',
    email: 'desjor49@yahoo.com',
  },
  {
    submitted: 'Jul 08, 2024',
    approved: 'Jul 08, 2024',
    property: '445 N Oakhurst Dr Beverly Hills, CA, 90210',
    unit: '106',
    name: 'Cesar Alejandro Hernandez',
    email: 'desjor49@yahoo.com',
  },
  {
    submitted: 'Jul 08, 2024',
    approved: 'Jul 08, 2024',
    property: '445 N Oakhurst Dr Beverly Hills, CA, 90210',
    unit: '106',
    name: 'Cesar Alejandro Hernandez',
    email: 'desjor49@yahoo.com',
  },
  {
    submitted: 'Jul 08, 2024',
    approved: 'Jul 08, 2024',
    property: '445 N Oakhurst Dr Beverly Hills, CA, 90210',
    unit: '106',
    name: 'Cesar Alejandro Hernandez',
    email: 'desjor49@yahoo.com',
  },
  {
    submitted: 'Jul 08, 2024',
    approved: 'Jul 08, 2024',
    property: '445 N Oakhurst Dr Beverly Hills, CA, 90210',
    unit: '106',
    name: 'Cesar Alejandro Hernandez',
    email: 'desjor49@yahoo.com',
  },
  {
    submitted: 'Jul 08, 2024',
    approved: 'Jul 08, 2024',
    property: '445 N Oakhurst Dr Beverly Hills, CA, 90210',
    unit: '106',
    name: 'Cesar Alejandro Hernandez',
    email: 'desjor49@yahoo.com',
  },
  {
    submitted: 'Jul 08, 2024',
    approved: 'Jul 08, 2024',
    property: '445 N Oakhurst Dr Beverly Hills, CA, 90210',
    unit: '106',
    name: 'Cesar Alejandro Hernandez',
    email: 'desjor49@yahoo.com',
  },
  {
    submitted: 'Jul 08, 2024',
    approved: 'Jul 08, 2024',
    property: '445 N Oakhurst Dr Beverly Hills, CA, 90210',
    unit: '106',
    name: 'Cesar Alejandro Hernandez',
    email: 'desjor49@yahoo.com',
  },
  {
    submitted: 'Jul 08, 2024',
    approved: 'Jul 08, 2024',
    property: '445 N Oakhurst Dr Beverly Hills, CA, 90210',
    unit: '106',
    name: 'Cesar Alejandro Hernandez',
    email: 'desjor49@yahoo.com',
  },
];

export const totalAppCount = [{ approved: 652, denied: 527, archived: 120, invited: 360 }];

export const tabs = [
  { eventKey: 'approved', label: 'Approved', count: 362 },
  { eventKey: 'denied', label: 'Denied', count: 106 },
  { eventKey: 'archived', label: 'Archived', count: 560 },
  { eventKey: 'invited', label: 'Invited', count: 150 },
];
