import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { renterRoutes } from 'shared/routes';

import { ApplicationOption } from '../ApplicationOption/ApplicationOption';

import styles from './ApplicationInfoBox.module.scss';
export const ApplicationOptions = ({ applicationId }: { applicationId: number }): JSX.Element => {
  const navigate = useNavigate();
  const onApplicationToRentClickHandler = useCallback(() => {
    navigate(renterRoutes.generateApplicationToRent(applicationId));
  }, [applicationId, navigate]);
  const { t } = useTranslation();

  return (
    <div className={styles.OptionsContainer}>
      <ApplicationOption
        onClickHandler={onApplicationToRentClickHandler}
        label={t('renter.viewApplication.applicationToRent')}
      />
      <ApplicationOption
        onClickHandler={() => navigate(renterRoutes.generateApplicationCreditBackground(applicationId))}
        label={t('application.creditAndBackground')}
      />
      <ApplicationOption
        onClickHandler={() => console.log(t('renter.application.options.agreements'))}
        label={t('renter.application.options.agreements')}
      />
      <ApplicationOption
        onClickHandler={() => console.log(t('renter.application.options.payment'))}
        label={t('renter.application.options.payment')}
      />
    </div>
  );
};
