import { useCallback, useMemo, useState } from 'react';
import { Col, Nav, Row } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { t } from 'i18next';
import { debounce } from 'lodash-es';

import SearchBar from 'components/shared/SearchBar/SearchBar';
import {
  INITIAL_USER_FILTER,
  SEARCH_DEBOUNCE_TIME,
  SORT_INITIAL_VALUE,
  UsersControlOptions,
} from 'constants/organizationConstants';
import { leagcyList } from 'fixtures/agentViewLegacyTable';
import { tabs } from 'fixtures/agentViewLegacyTable';
import { SortParams } from 'shared/types/organizationTypes';

import styles from './ViewLegacyTable.module.scss';
interface LegacyItem {
  submitted: string;
  approved: string;
  property: string;
  unit: string;
  name: string;
  email: string;
}

interface TableContentProps {
  data: LegacyItem[];
}
const TableContent: React.FC<TableContentProps> = ({ data }) => (
  <table className={classNames(styles.CustomTable, 'table')}>
    <thead className="gap-2">
      <tr>
        <th scope="col">SUBMITTED</th>
        <th scope="col">APPROVED</th>
        <th scope="col">PROPERTY</th>
        <th scope="col">UNIT/APT #</th>
        <th scope="col">NAME</th>
        <th scope="col">EMAIL</th>
      </tr>
    </thead>
    <tbody>
      {data.map((item, index) => (
        <tr key={index}>
          <td className={styles.CursorPointer}>{item.submitted}</td>
          <td>{item.approved}</td>
          <td>{item.property}</td>
          <td>{item.unit}</td>
          <td>{item.name}</td>
          <td>{item.email}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

export const ViewLegacyTable = (): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchValue = searchParams.get(UsersControlOptions.TARGET) || '';
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [, setSortOptions] = useState<SortParams>({
    sortBy: searchParams.get(UsersControlOptions.SORTBY) || SORT_INITIAL_VALUE.sortBy,
    sortType: searchParams.get(UsersControlOptions.SORTTYPE) || SORT_INITIAL_VALUE.sortType,
  });
  const [filterValue] = useState({
    value: searchParams.get(UsersControlOptions.FILTER) || INITIAL_USER_FILTER.value,
    label: searchParams.get(UsersControlOptions.FILTER) || INITIAL_USER_FILTER.label,
  });
  const handleSearch = useCallback(
    (target: string): void => {
      setSearchParams({
        target: target,
        sortBy: SORT_INITIAL_VALUE.sortBy,
        sortType: SORT_INITIAL_VALUE.sortType,
        filter: filterValue.value,
      });
      setSortOptions(SORT_INITIAL_VALUE);
    },
    [filterValue, setSearchParams]
  );
  const searchResults = useMemo(() => debounce(handleSearch, SEARCH_DEBOUNCE_TIME), [handleSearch]);

  return (
    <div className={styles.TableContainer}>
      <Tab.Container defaultActiveKey="approved">
        <div className={styles.TableTopContainer}>
          <Row>
            <Nav variant="tabs" className="flex-row">
              {tabs.map((tab) => (
                <Col key={tab.eventKey}>
                  <Nav.Item className={styles.NavItem}>
                    <Nav.Link eventKey={tab.eventKey} className={styles.NavLink}>
                      <span>
                        {tab.label} <span className={styles.CountHeading}>({tab.count} Apps)</span>
                      </span>
                    </Nav.Link>
                  </Nav.Item>
                </Col>
              ))}
            </Nav>
          </Row>
          <div className={styles.SearchContainer}>
            <SearchBar
              className={styles.SearchBar}
              value={searchValue}
              handleSearch={searchResults}
              placeholder={t('application.search.all')}
            />
          </div>
        </div>
        <Row>
          <Col sm={12}>
            <Tab.Content>
              <Tab.Pane eventKey="approved">
                <TableContent data={leagcyList} />
              </Tab.Pane>
              <Tab.Pane eventKey="denied">
                <TableContent data={leagcyList} />
              </Tab.Pane>
              <Tab.Pane eventKey="archived">
                <TableContent data={leagcyList} />
              </Tab.Pane>
              <Tab.Pane eventKey="invited">
                <TableContent data={leagcyList} />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
};
