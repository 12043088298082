import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { isEmpty, omitBy } from 'lodash-es';
import { applicantRenterRole, selectRenterProfileInformation } from 'redux/selectors/renterSelector';
import { getRenterProfileInformation, setIsRenterLoading } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';

import { ReactComponent as EditIcon } from 'assets/svgs/Edit.svg';
import RCButton from 'components/shared/Button/Button';
import { RenterAddressSteps } from 'constants/renterConstants';
import { renterRestrictions } from 'constants/restrictionConstants';
import { formatRenterName, formatSSN } from 'helpers/renterHelper';
import { formattedDateString } from 'helpers/user';
import { getStripeSensitiveInformation } from 'services/stripeService';
import { Notification } from 'shared/Notification/Notification';
import { renterRoutes } from 'shared/routes';
import { RenterInformationEditProps, RenterProfileInformationInitialValue } from 'shared/types/renterTypes';
import { StripeErrorCode } from 'shared/types/stripeType';
import { formatDateForBackend } from 'shared/utils/formatDate';

import { PersonalProfileEditModal } from './PersonalProfileEditModal/PersonalProfileEditModal';
import RenterInfoDetails from './RenterInfoDetails/RenterInfoDetails';
import RenterInfoDetailsModal from './RenterInfoDetailsModal/RenterInfoDetailsModal';
import { RenterPersonalInfoModal } from './RenterPersonalInfoModal/RenterPersonalInfoModal';
import RenterIconInfoContainer from './RenterIconInfoContainer';

import styles from './RenterPersonalInfoSummary.module.scss';

const RenterPersonalInfoSummary = ({ handleNext }: RenterInformationEditProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppThunkDispatch>();
  const navigate = useNavigate();
  const { applicationId } = useParams();
  const [shouldShowModal, setShowModal] = useState(false);
  const renterDetails = useSelector(selectRenterProfileInformation);
  const [shouldShowContactInfoModal, setShouldShowContactInfoModal] = useState(false);
  const [shouldShowPersonalInfoModal, setShouldShowPersonalInfoModal] = useState(false);
  const [documentData, setDocumentData] = useState({ id: '---', type: '---' });
  const { values, setErrors } = useFormikContext<typeof RenterProfileInformationInitialValue>();
  const renterRole = useSelector(applicantRenterRole);
  const handleShowModal = useCallback((willShowModal: boolean): void => setShowModal(willShowModal), []);
  const handleStripeInfoModal = (): void => {
    setShouldShowPersonalInfoModal(true);
    setShowModal(false);
  };
  const handleSubmit = useCallback(() => {
    const { firstName, lastName, middleName } = values;
    const formValues = { firstName, lastName, middleName: !!middleName ? middleName : null };

    console.log(formValues);

    if (handleNext) {
      handleNext({
        params: formValues,
        nextStep: '',
        setErrors,
        successCallback: () => {
          setShowModal(false);
        },
      });
    }
  }, [values, handleNext, setErrors]);
  const handleDobSubmit = useCallback(() => {
    const { birthDate } = values;
    const formValues = { birthDate: formatDateForBackend(birthDate) };

    if (handleNext) {
      handleNext({
        params: omitBy(formValues, isEmpty),
        nextStep: '',
        setErrors,
        successCallback: () => {
          setShouldShowPersonalInfoModal(false);
          setShowModal(true);
        },
      });
    }
  }, [values, handleNext, setErrors]);

  useEffect(() => {
    if (renterRestrictions[renterRole].profile) {
      navigate(renterRoutes.generatePersonalInformationUrl(RenterAddressSteps.PRESENT_ADDRESS, Number(applicationId)));
    } else {
      if (renterDetails?.stripeVerified) {
        dispatch(setIsRenterLoading(true));
        getStripeSensitiveInformation()
          .then((res: AxiosResponse) => {
            const { payload } = res.data;

            setDocumentData({ id: payload.documentNumber, type: payload.documentType });
          })
          .catch((err) => {
            if (err?.response?.data?.errors[0]?.errorCode === StripeErrorCode.CONSENT_DECLINED) {
              Notification({ message: t('renter.PhotoIdInformation.stripeDocument.error') });
              dispatch(getRenterProfileInformation());
              navigate(renterRoutes.generateRenterStripeIdentityUrl(Number(applicationId)));
            }
          })
          .finally(() => dispatch(setIsRenterLoading(false)));
      }
    }
  }, [applicationId, dispatch, navigate, renterDetails?.stripeVerified, renterRole, t]);

  return (
    <div className={styles.Container}>
      <RenterIconInfoContainer />
      <div className={classNames(styles.HeaderContainer, 'text-start"')}>
        <span>{t('renter.CollectedInformation.summaryHeading')}</span>
        <div className={classNames(styles.employmentContainer, 'documentRow')}></div>
      </div>
      {/* <PersonalProfile /> */}
      <RenterInfoDetails
        name={formatRenterName(renterDetails)}
        dateOfBirth={formattedDateString(renterDetails.birthDate)}
        idType={documentData.type ?? '---'}
        idNumber={documentData.id ?? '---'}
        onEditPress={() => handleShowModal(true)}
        hasMiddleNameCheck={!renterDetails.middleName}
        customParentClass={styles.RenterInfoDetailsContainer}
      />
      <div className={styles.ContactInformationContainer}>
        <div className={styles.ContactHeader}>
          <div className={styles.Heading}>{t('application.contact')}</div>
          <EditIcon
            onClick={() => {
              setShouldShowContactInfoModal(true);
            }}
            className={styles.EditIcon}
          />
        </div>
        <div className={styles.ValueContainer}>
          <div className={styles.Value}>{renterDetails.email}</div>
          <div className={styles.Label}>{t('application.email')}</div>
        </div>
        {renterDetails?.renterPhoneNumbers?.map((phoneNumber) => (
          <div key={`phone-number-${phoneNumber?.id}`} className={styles.ValueContainer}>
            <div className={styles.Value}>{phoneNumber.phoneNumber}</div>
            <div className={styles.Label}>{phoneNumber.type}</div>
          </div>
        ))}
      </div>
      <div className={styles.RenterSSNContainer}>
        <span className={styles.SSNHeading}>{t('application.ssn')}</span>
        <span className={`${styles.SSNContainer} fs-mask`}>
          {formatSSN(String(renterDetails?.ssnTailDigits), true)}
        </span>
      </div>
      <RCButton
        variant="outline"
        onClick={() => {
          navigate(renterRoutes.generateGuarantorInformation(applicationId));
        }}
        className={styles.ContinueButton}
      >
        {t('renter.relationship.minorConfirmButton')}
      </RCButton>
      {shouldShowModal && (
        <RenterInfoDetailsModal
          shouldShowModal={shouldShowModal}
          onCloseModal={() => handleShowModal(false)}
          onSubmit={handleSubmit}
          dateOfBirth={formattedDateString(renterDetails.birthDate)}
          documentNumber={documentData.id}
          documentType={documentData.type}
          handleStripeInfoModal={handleStripeInfoModal}
        />
      )}
      {shouldShowContactInfoModal && (
        <PersonalProfileEditModal
          onHide={() => {
            setShouldShowContactInfoModal(false);
          }}
          shouldShowModal={shouldShowContactInfoModal}
        />
      )}
      {process.env.REACT_APP_ENV !== 'production' && shouldShowPersonalInfoModal && (
        <RenterPersonalInfoModal
          shouldShowModal={shouldShowPersonalInfoModal}
          onCloseModal={() => {
            setShouldShowPersonalInfoModal(false);
            setShowModal(true);
          }}
          onSubmit={handleDobSubmit}
        />
      )}
    </div>
  );
};

export default RenterPersonalInfoSummary;
