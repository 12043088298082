import { ReactComponent as infoErrorSvg } from 'assets/svgs/InfoError.svg';
import { ReactComponent as DangerErrorSvg } from 'assets/svgs/InfoErrorDanger.svg';

import { ErrorStatusDetailsProps } from './types/agentTypes';
import { PhoneMasks } from './types/renterTypes';
import { routes } from './routes';

// This file can contain various constants that are used throughout the app
export enum StatusCodes {
  OK = 200,
  CREATED = 201,
  PARTIAL_SUCCESS = 206,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500,
}

export const STATUS_NETWORK_ERROR = 'ERR_NETWORK';
export const PHONE_NUMBER_DIAL_CODE_LIMIT = 4;

export const PDF_QUESTIONS_PER_PAGE_MAX = 4;

export const phoneMasks: PhoneMasks = {
  ac: '+###-####',
  ad: '+###-###-###',
  ae: '+###-#-###-####',
  af: '+##-##-###-####',
  ag: '+#(###)###-####',
  ai: '+#(###)###-####',
  al: '+###(###)###-###',
  am: '+###-##-###-###',
  an: '+###-####-####',
  ao: '+###(###)###-###',
  aq: '+###-###-###',
  ar: '+##(###)###-####',
  as: '+#(###)###-####',
  at: '+##(###)###-####',
  au: '+##-#-####-####',
  aw: '+###-###-####',
  az: '+###-##-###-##-##',
  ba: '+###-##-####',
  bb: '+#(###)###-####',
  bd: '+###-##-###-###',
  be: '+##(###)###-###',
  bf: '+###-##-##-####',
  bg: '+###(###)###-###',
  bh: '+###-####-####',
  bi: '+###-##-##-####',
  bj: '+###-##-##-####',
  bm: '+#(###)###-####',
  bn: '+###-###-####',
  bo: '+###-#-###-####',
  br: '+##(##)#####-####',
  bs: '+#(###)###-####',
  bt: '+###-#-###-###',
  bw: '+###-##-###-###',
  by: '+###(##)###-##-##',
  bz: '+###-###-####',
  ca: '+#(###)###-####',
  cd: '+###(###)###-###',
  cf: '+###-##-##-####',
  cg: '+###-##-###-####',
  ch: '+##-##-###-####',
  ci: '+###-##-###-###',
  ck: '+###-##-###',
  cl: '+##-#-####-####',
  cm: '+###-####-####',
  cn: '+##-##-#####-#####',
  co: '+##(###)###-####',
  cr: '+###-####-####',
  cu: '+##-#-###-####',
  cv: '+###(###)##-##',
  cw: '+###-###-####',
  cy: '+###-##-###-###',
  cz: '+###(###)###-###',
  de: '+##-###-###',
  dj: '+###-##-##-##-##',
  dk: '+##-##-##-##-##',
  dm: '+#(###)###-####',
  do: '+#(###)###-####',
  dz: '+###-##-###-####',
  ec: '+###-#-###-####',
  ee: '+###-###-####',
  eg: '+##(###)###-####',
  er: '+###-#-###-###',
  es: '+##(###)###-###',
  et: '+###-##-###-####',
  fi: '+###(###)###-##-##',
  fj: '+###-##-#####',
  fk: '+###-#####',
  fm: '+###-###-####',
  fo: '+###-###-###',
  fr: '+###(###)###-###',
  ga: '+###-#-##-##-##',
  gd: '+#(###)###-####',
  ge: '+###(###)###-###',
  gf: '+###-#####-####',
  gh: '+###(###)###-###',
  gi: '+###-###-#####',
  gl: '+###-##-##-##',
  gm: '+###(###)##-##',
  gn: '+###-##-###-###',
  gq: '+###-##-###-####',
  gr: '+##(###)###-####',
  gt: '+###-#-###-####',
  gu: '+#(###)###-####',
  gw: '+###-#-######',
  gy: '+###-###-####',
  hk: '+###-####-####',
  hn: '+###-####-####',
  hr: '+###-##-###-###',
  ht: '+###-##-##-####',
  hu: '+##(###)###-###',
  id: '+##(###)###-##-###',
  ie: '+###(###)###-###',
  il: '+###-#-###-####',
  in: '+##(####)###-###',
  io: '+###-###-####',
  iq: '+###(###)###-####',
  ir: '+##(###)###-####',
  is: '+###-###-####',
  it: '+##(###)####-###',
  jm: '+#(###)###-####',
  jo: '+###-#-####-####',
  jp: '+##(###)###-###',
  ke: '+###-###-######',
  kg: '+###(###)###-###',
  kh: '+###-##-###-###',
  ki: '+###-##-###',
  km: '+###-##-#####',
  kn: '+#(###)###-####',
  kp: '+###-####-####',
  kr: '+##-##-###-####',
  kw: '+###-####-####',
  ky: '+#(###)###-####',
  kz: '+#(###)###-##-##',
  la: '+###-##-###-###',
  lb: '+###-#-###-###',
  lc: '+#(###)###-####',
  li: '+###(###)###-####',
  lk: '+##-##-###-####',
  lr: '+###-##-###-###',
  ls: '+###-#-###-####',
  lt: '+###(###)##-###',
  lu: '+###(###)###-###',
  lv: '+###-##-###-###',
  ly: '+###-##-###-####',
  ma: '+###-##-####-###',
  mc: '+###-##-###-###',
  md: '+###-####-####',
  me: '+###-##-###-###',
  mg: '+###-##-##-#####',
  mh: '+###-###-####',
  mk: '+###-##-###-###',
  ml: '+###-##-##-####',
  mm: '+##-###-###',
  mn: '+###-##-##-####',
  mo: '+###-####-####',
  mp: '+#(###)###-####',
  mq: '+###(###)##-##-##',
  mr: '+###-##-##-####',
  ms: '+#(###)###-####',
  mt: '+###-####-####',
  mu: '+###-###-####',
  mv: '+###-###-####',
  mw: '+###-#-####-####',
  mx: '+##-##-##-####',
  my: '+##-#-###-###',
  mz: '+###-##-###-###',
  na: '+###-##-###-####',
  nc: '+###-##-####',
  ne: '+###-##-##-####',
  nf: '+###-###-###',
  ng: '+###(###)###-####',
  ni: '+###-####-####',
  nl: '+##-##-###-####',
  no: '+##(###)##-###',
  np: '+###-##-###-###',
  nr: '+###-###-####',
  nu: '+###-####',
  nz: '+##(###)###-####',
  om: '+###-##-###-###',
  pa: '+###-###-####',
  pe: '+##(###)###-###',
  pf: '+###-##-##-##',
  pg: '+###(###)##-###',
  ph: '+##(###)###-####',
  pk: '+##(###)###-####',
  pl: '+##(###)###-###',
  ps: '+###-##-###-####',
  pt: '+###-##-###-####',
  pw: '+###-###-####',
  py: '+###(###)###-###',
  qa: '+###-####-####',
  re: '+###-#####-####',
  ro: '+##-##-###-####',
  rs: '+###-##-###-####',
  ru: '+#(###)###-##-##',
  rw: '+###(###)###-###',
  sa: '+###-#-###-####',
  sb: '+###-#####',
  sc: '+###-#-###-###',
  sd: '+###-##-###-####',
  se: '+##-##-###-####',
  sg: '+##-####-####',
  sh: '+###-####',
  si: '+###-##-###-###',
  sk: '+###(###)###-###',
  sl: '+###-##-######',
  sm: '+###-####-######',
  sn: '+###-##-###-####',
  so: '+###-#-###-###',
  sr: '+###-###-###',
  ss: '+###-##-###-####',
  st: '+###-##-#####',
  sv: '+###-##-##-####',
  sx: '+#(###)###-####',
  sy: '+###-##-####-###',
  sz: '+###-##-##-####',
  tc: '+#(###)###-####',
  td: '+###-##-##-##-##',
  tg: '+###-##-###-###',
  th: '+##-##-###-###',
  tj: '+###-##-###-####',
  tk: '+###-####',
  tl: '+###-###-#####',
  tm: '+###-#-###-####',
  tn: '+###-##-###-###',
  to: '+###-#####',
  tr: '+##(###)###-####',
  tt: '+#(###)###-####',
  tv: '+###-#####',
  tw: '+###-####-####',
  tz: '+###-##-###-####',
  ua: '+###(##)###-##-##',
  ug: '+###(###)###-###',
  gb: '+##-##-####-####',
  uk: '+##-##-####-####',
  us: '+#(###)###-####',
  pr: '+#(###)###-####',
  uy: '+###-#-###-##-##',
  uz: '+###-##-###-####',
  va: '+##-#-###-#####',
  vc: '+#(###)###-####',
  ve: '+##(###)###-####',
  vg: '+#(###)###-####',
  vi: '+#(###)###-####',
  vn: '+##(###)####-###',
  vu: '+###-#####',
  wf: '+###-##-####',
  ws: '+###-##-####',
  ye: '+###-##-###-###',
  za: '+##-##-###-####',
  zm: '+###-##-###-####',
  zw: '+###-#-######',
};

export enum KeyboadKeys {
  BACKSPACE = 'Backspace',
  TAB = 'Tab',
}

export enum IntellirentPublicUrls {
  TERMS_AND_POLICY = 'https://myintellirent.com/applicant-terms-of-use',
  KNOWLEDGE_BASE = 'https://myintellirent.zendesk.com/hc/en-us/categories/115002153528-For-Agents',
  RENTER_TERMS_OF_USE = 'https://myintellirent.com/renter_terms',
  PRIVACY_POLICY = 'https://myintellirent.com/privacy_policy',
  CONTACT_SUPPORT = 'https://myintellirent.zendesk.com/hc/en-us/requests/new',
  NEW_REQUEST = 'https://myintellirent.zendesk.com/hc/en-us/requests/new',
}

export enum Colors {
  COLOR_SVG_GRAY_LIGHT = '#A3B0B2',
  WARM_GRAY = '#8D9899',
  COLOR_SVG_NEUTRAL_BLACK_PRIMARY = '#5E6566',
  WARM_CYAN_LIGHT = '#F7FAFA',
}
export const errorStatusDetails: { [key: number]: ErrorStatusDetailsProps } = {
  [StatusCodes.INTERNAL_SERVER_ERROR]: {
    value: '500',
    mainHeading: 'modal.internelServerErrorMessage.heading',
    subHeading: '',
    description: undefined,
    ErrorIcon: DangerErrorSvg,
    route: routes.internalServerError,
  },
  [StatusCodes.NOT_FOUND]: {
    value: '404',
    mainHeading: 'modal.ServerNotFoundModal.heading',
    subHeading: 'modal.ServerNotFoundModal.subHeading',
    description: 'modal.ServerNotFoundModal.subHeading2',
    ErrorIcon: infoErrorSvg,
    route: routes.pageNotFound,
  },
};

export const otpFieldMaxDigits = 6;
export const otpExpireyTime = 5;
export const EXPERIAN_EXPIREY_ERROR_CODE = '2500';
export const EXPERIAN_USER_BLOCKED_ERROR_CODE = 300;

export const RENTER_START_A_DISPUTE_URL = 'https://myintellirent.com/renter-dispute-experian-report';
export const VERIFICATION_CODE_LENGTH = 4;
