import { PropertyApplications } from 'redux/slices/agentSlice';

import { AddressVerificationResponse, AgentProfileInformationProps } from 'shared/types/agentTypes';
import { ApplicationType } from 'shared/types/applicantsType';
import { AddPropertyParams } from 'shared/types/propertyType';
import { AddressInformationResponse, RenterProfileInformationProps } from 'shared/types/renterTypes';
import { BackgroundData, CreditProfileReport, CriminalReportItem, EvictionReportItem } from 'shared/types/reportTypes';

import { RootState } from '../types';

export const isAgentLoading = (state: RootState): boolean => state.agentSlice.isLoading;
export const agentId = (state: RootState): number | undefined => state.agentSlice.profileInformation.agentId;
export const selectAgentProfileInformation = (state: RootState): AgentProfileInformationProps =>
  state.agentSlice.profileInformation;

//Selected Renter Selector
//All the Details
export const selectedRenterDetails = (state: RootState): RenterProfileInformationProps =>
  state.agentSlice.selectedRenter;

//Renter Addresses
export const selectedRenterAddress = (state: RootState): AddressInformationResponse[] | undefined =>
  state.agentSlice.selectedRenter.addresses;

//First Application Guarantor
export const selectPropertyApplications = (state: RootState): PropertyApplications =>
  state.agentSlice.propertyApplications;

//Renter Experian Credit Profile
export const selectRenterCreditProfile = (state: RootState): CreditProfileReport =>
  state.agentSlice.renterExperianReports?.reports?.CreditProfile;
//Renter Experian Background Data Report
export const selectRenterBackgroundDataReport = (state: RootState): BackgroundData =>
  state.agentSlice.renterExperianReports?.reports?.BackgroundData;
//Renter Experian Eviction Report
export const selectRenterEvictionReport = (state: RootState): EvictionReportItem =>
  state.agentSlice.renterExperianReports?.reports?.BackgroundData?.report?.evictionReport ?? ({} as EvictionReportItem);
//Renter Experian Criminal Report
export const selectRenterCriminalReport = (state: RootState): CriminalReportItem =>
  state.agentSlice.renterExperianReports?.reports?.BackgroundData?.report?.criminalReport ?? ({} as CriminalReportItem);
export const selectRenterApplication = (state: RootState): ApplicationType =>
  state.agentSlice.selectedRenterApplication;

export const selectCurrentProperty = (state: RootState): AddPropertyParams => state.agentSlice.selectedProperty;

export const selectVerifierAddressInformation = (state: RootState): AddressVerificationResponse[] =>
  state.agentSlice.verifierAddressInformation;

export const selectVerifierEmploymentInformation = (state: RootState): AddressVerificationResponse[] =>
  state.agentSlice.verifierEmploymentInformation;
