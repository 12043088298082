import { PreviewFile } from 'components/shared/DropZone/MinimizedDropzone/MinimizedDropzone';
import { ApplicationPropertyLinkProps } from 'shared/types/applicantsType';
import { AddCustomPropertyParams, PropertyProps } from 'shared/types/propertyType';
import { AgentDetailsResponse, PropertyAdditionalInfo } from 'shared/types/renterApplication';
import { BackgroundInfo } from 'shared/types/renterBackgroundInformation';
import {
  AddressInformationResponse,
  AnimalInformationProps,
  RenterApplicationProps,
  RenterPresentEmployments,
  RenterProfileInformationProps,
} from 'shared/types/renterTypes';
import { RenterRoles } from 'shared/types/renterTypes';
import { VehiclesInformationType } from 'shared/types/renterVehicleInformation';
import { BackgroundData, CreditProfileReport, CriminalReportItem, EvictionReportItem } from 'shared/types/reportTypes';

import { RootState } from '../types';

export const renterId = (state: RootState): number | undefined => state.renterSlice.renterId;
export const isFormDirty = (state: RootState): boolean => state.renterSlice.isDirty;
export const selectRenterProfileInformation = (state: RootState): RenterProfileInformationProps =>
  state.renterSlice.renterProfileInformation;
export const renterPresentEmployments = (state: RootState): RenterPresentEmployments[] =>
  state.renterSlice.renterProfileInformation.presentEmployments;
export const renterPriorEmployments = (state: RootState): RenterPresentEmployments[] =>
  state.renterSlice.renterProfileInformation.priorEmployments;
export const RenterAddresses = (state: RootState): AddressInformationResponse[] | undefined =>
  state.renterSlice.renterProfileInformation.addresses;
export const isLoading = (state: RootState): boolean => state.renterSlice.isLoading;
export const isSaving = (state: RootState): boolean => state.renterSlice.isSaving;
export const isStripeVerified = (state: RootState): boolean =>
  state.renterSlice.renterProfileInformation.stripeVerified;
export const renterApplication = (state: RootState): RenterApplicationProps[] =>
  state.renterSlice.renterProfileInformation?.applications;
export const renterVehicles = (state: RootState): VehiclesInformationType[] =>
  state.renterSlice.renterProfileInformation.vehicles;
export const renterAnimals = (state: RootState): AnimalInformationProps[] =>
  state.renterSlice.renterProfileInformation.furryFriends;
export const uploadedDocuments = (state: RootState, employmentId: number): PreviewFile[] | undefined => {
  const presentEmployments = renterPresentEmployments(state); // Get all documents using the previous selector
  const currentPresentEmployment = presentEmployments.find(
    (presentEmployment) => presentEmployment?.id === employmentId
  );

  return currentPresentEmployment?.documents;
};

export const applicationProperties = (state: RootState): AddCustomPropertyParams => state.renterSlice.selectedProperty;

export const applicantRenterRole = (state: RootState): RenterRoles => {
  const currentApplication = state.renterSlice.renterProfileInformation?.applications.find(
    (application) => application.id === state.authSlice.applicationId
  );
  const role: RenterRoles = currentApplication?.renterRole ?? RenterRoles.PRIMARY;

  return role ?? RenterRoles.PRIMARY;
};

export const backgroundInformation = (state: RootState): BackgroundInfo[] => {
  const currentApplication = state.renterSlice.renterProfileInformation?.applications.find(
    (application) => application.id === state.authSlice.applicationId
  );

  return currentApplication?.backgroundInformation ?? [];
};
export const applicationPropertyLink = (state: RootState): ApplicationPropertyLinkProps =>
  state.renterSlice.applicationPropertyLink;

export const selectRenterApplicationProperties = (state: RootState): Record<number, PropertyProps> =>
  state.renterSlice.applicationProperties;
export const selectedAgent = (state: RootState): AgentDetailsResponse => state.renterSlice?.selectedAgentDetails;

export const selectApplicationReportConsent = (state: RootState): boolean | undefined => {
  const currentApplication = state.renterSlice.renterProfileInformation?.applications.find(
    (application) => application.id === state.authSlice.applicationId
  );

  return currentApplication?.reportConsent;
};

//Renter Experian Credit Profile
export const selectRenterCreditProfile = (state: RootState): CreditProfileReport =>
  state.renterSlice.renterExperianReports?.reports?.CreditProfile;
//Renter Experian Background Data Report
export const selectRenterBackgroundDataReport = (state: RootState): BackgroundData =>
  state.renterSlice.renterExperianReports?.reports?.BackgroundData;
//Renter Experian Eviction Report
export const selectRenterEvictionReport = (state: RootState): EvictionReportItem =>
  state.renterSlice.renterExperianReports?.reports?.BackgroundData.report?.evictionReport ?? ({} as EvictionReportItem);
//Renter Experian Criminal Report
export const selectRenterCriminalReport = (state: RootState): CriminalReportItem =>
  state.renterSlice.renterExperianReports?.reports?.BackgroundData.report?.criminalReport ?? ({} as CriminalReportItem);
export const selectPropertyAdditionalInfo = (state: RootState): PropertyAdditionalInfo =>
  state.renterSlice?.propertyAdditionalInfo ?? ({} as PropertyAdditionalInfo);
