import { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import Pagination from 'react-bootstrap/Pagination';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { SingleValue } from 'react-select';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import { range } from 'lodash-es';
import { isLoading } from 'redux/selectors/propertySelector';

import { PerPageProps } from 'components/Agent/Properties/PropertyIndex/PropertyIndex';
import { PaginationType } from 'shared/types/paginationTypes';
import { perPageOptions } from 'shared/types/propertyType';

import ReactSelect from '../ReactSelect/ReactSelect';

import styles from './Pagination.module.scss';

export type PaginationProps = {
  pagination: PaginationType;
  handlePageChange: (page: number) => void;
};
const BSPagination = ({ pagination, handlePageChange }: PaginationProps): JSX.Element => {
  const isPageLoading = useSelector(isLoading);
  const [searchParams, setSearchParams] = useSearchParams({
    option: 'Published',
    perPage: Cookies.get('perPage') || '10',
    page: '1',
  });
  const { page: currentPage, pages } = pagination;
  const pagesToShow = useMemo((): number[] => {
    if (!currentPage || !pages) {
      return [1];
    }

    let maxPage = Math.min(pages, currentPage + 2);
    let minPage = Math.max(1, maxPage - 5);

    if (currentPage < 4) {
      maxPage = Math.min(pages, 6);
      minPage = 1;
    } else if (currentPage > pages - 3) {
      maxPage = pages;
      minPage = Math.max(1, maxPage - 5);
    }

    return range(minPage, maxPage + 1);
  }, [currentPage, pages]);
  const handlePerPage = (selectedOption: SingleValue<PerPageProps>): void => {
    if (selectedOption) {
      Cookies.set('perPage', selectedOption.value);
      searchParams.set('page', '1');
      searchParams.set('perPage', selectedOption.value);
      setSearchParams(searchParams);
    }
  };

  return (
    <Row>
      <Col lg={4} md={3} sm={12} />
      <Col lg={4} md={6} sm={12} className={styles.paginationCol}>
        <Pagination>
          {currentPage && (
            <>
              <Pagination.Prev
                disabled={currentPage === 1}
                className={styles.pageItem}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <FontAwesomeIcon icon={faChevronLeft} /> Prev
              </Pagination.Prev>
              {pagesToShow.map((number) => (
                <Pagination.Item
                  key={number}
                  onClick={() => handlePageChange(number)}
                  className={classNames(styles.pageItem, { [styles.focus]: number === currentPage })}
                >
                  {number}
                </Pagination.Item>
              ))}
              <Pagination.Next
                disabled={!pagination?.next}
                className={styles.pageItem}
                onClick={() => handlePageChange(currentPage + 1)}
                data-testid="next-page"
              >
                Next <FontAwesomeIcon icon={faChevronRight} />
              </Pagination.Next>
            </>
          )}
        </Pagination>
      </Col>
      <Col lg={4} md={3} sm={12} className={styles.perPageCol}>
        {!isPageLoading && (
          <ReactSelect
            options={perPageOptions}
            value={perPageOptions.find((option) => option.value === searchParams.get('perPage'))}
            onChange={handlePerPage}
            aria-label="react-dropdown-custom"
            // eslint-disable-next-line @typescript-eslint/naming-convention
            components={{ DropdownIndicator: () => null }}
            backgroundColor="#f6f9fa"
            borderStyle="none"
            isSearchable={false}
          />
        )}
      </Col>
    </Row>
  );
};

export default BSPagination;
