import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { t } from 'i18next';
import { isEmpty } from 'lodash-es';
import { applicantRenterRole, selectRenterProfileInformation } from 'redux/selectors/renterSelector';

import Button from 'components/shared/Button/Button';
import { PaymentVerificationKeys, PaymentVerificationSteps } from 'constants/renterConstants';
import { scrollToViewOfGivenId } from 'helpers/agentHelper';
import { tabTitle } from 'helpers/helper';
import { isComponentRestricted } from 'helpers/renterHelper';
import { useDeviceHeight } from 'hooks/useDeviceHeight';
import { renterRoutes } from 'shared/routes';

import CollapsibleComponent from '../components/CollapsibleComponent/CollapsibleComponent';
import RenterContainer from '../components/RenterContainer';
import RenterIconInfoContainer from '../components/RenterIconInfoContainer';

import {
  AddressHistory,
  AnimalsInformation,
  BackgroundQuestionsVerification,
  HouseholdDetails,
  PersonalProfile,
  SourceOfIncome,
  VehiclesInformation,
} from './RenterDetails/index';

import indexStyles from '../RenterIndex.module.scss';
import styles from './IdentityVerification.module.scss';

export const identityVerificationComponents = {
  [PaymentVerificationKeys.PERSONAL_PROFILE]: <PersonalProfile />,
  [PaymentVerificationKeys.ADDRESS_HISTORY]: <AddressHistory />,
  [PaymentVerificationKeys.ANIMAL_INFORMATION]: <AnimalsInformation />,
  [PaymentVerificationKeys.BACKGROUND_QUESTIONS]: <BackgroundQuestionsVerification />,
  [PaymentVerificationKeys.HOUSEHOLD_DETAILS]: <HouseholdDetails />,
  [PaymentVerificationKeys.SOURCE_OF_INCOME]: <SourceOfIncome />,
  [PaymentVerificationKeys.VEHICLE_INFORMATION]: <VehiclesInformation />,
};
const IdentityVerification = (): JSX.Element => {
  tabTitle('Your Application | Summary');
  const location = useLocation();
  const { applicationId } = useParams();
  const navigate = useNavigate();
  const maxHeight = useDeviceHeight(220);
  const {
    renterPhoneNumbers,
    addresses,
    firstName,
    lastName,
    birthDate,
    email,
    isPaymentSucceeded,
    ssnTailDigits,
    presentEmployments,
  } = useSelector(selectRenterProfileInformation);
  const renterRole = useSelector(applicantRenterRole);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const sectionId = queryParams.get('section');

    if (sectionId) {
      scrollToViewOfGivenId(sectionId);
    }
  }, [location]);
  const isButtonDisabled = useMemo(
    (): boolean =>
      isEmpty(renterPhoneNumbers) ||
      isEmpty(addresses) ||
      isEmpty(presentEmployments) ||
      !firstName ||
      !lastName ||
      !birthDate ||
      !email ||
      !ssnTailDigits,
    [renterPhoneNumbers, addresses, presentEmployments, firstName, lastName, birthDate, email, ssnTailDigits]
  );

  return (
    <RenterContainer>
      <section className={indexStyles.renterBody}>
        <div
          className={styles.container}
          style={{ paddingRight: '12px', maxHeight, paddingBottom: '20px', overflowY: 'auto' }}
        >
          <RenterIconInfoContainer />
          <div className={styles.mainHeading}>{t('renter.paymentConfirmation.heading')}</div>
          <div className={styles.IdentityVerificationContainer}>
            {PaymentVerificationSteps.map(
              (step) =>
                !isComponentRestricted(renterRole, step) && (
                  <div key={step} id={step}>
                    <CollapsibleComponent heading={step}>{identityVerificationComponents[step]}</CollapsibleComponent>
                  </div>
                )
            )}
            <div className={styles.buttonContainer}>
              <Button
                onClick={() => navigate(renterRoutes.generateRenterReportUrl(applicationId))}
                disabled={isButtonDisabled}
              >
                {t(
                  `renter.paymentConfirmation.personalProfile.${
                    isPaymentSucceeded ? 'confirmButton' : 'confirmAndPaymentButton'
                  }`
                )}
              </Button>
            </div>
          </div>
        </div>
      </section>
    </RenterContainer>
  );
};

export default IdentityVerification;
