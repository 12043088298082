//TODO: Temporarily disabled SendRequestDropDown component and its dependancies, will re-enable later.
import { useCallback, useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SingleValue } from 'react-select';
import classNames from 'classnames';
import { capitalize, isEmpty } from 'lodash-es';
import { organizationInvites, selectedOrganization } from 'redux/selectors/organizationSelector';
import { selectApplicationProperties } from 'redux/selectors/propertySelector';
import { resetPropertyApplications } from 'redux/slices/agentSlice';
import { getInvites, resetInvites } from 'redux/slices/organizationSlice';
import { getApplicationProperties, resetApplicationProperties } from 'redux/slices/propertySlice';
import { AppThunkDispatch } from 'redux/store';

import { ReactComponent as ArrowDown } from 'assets/svgs/ArrowDownWithBackgroundColor.svg';
import { EmptyState } from 'components/Agent/Applications/ApplicationsList/components';
// import { SendRequestDropDown } from 'components/Agent/Applications/ApplicationsList/components/SendRequestDropDown/SendRequestDropDown';
import { ViewGeneralInvite } from 'components/Agent/Applications/ApplicationsList/components/ViewGeneralInvite/ViewGeneralInvite';
import { ViewLegacyTable } from 'components/Agent/Applications/ApplicationsList/components/ViewLegacyTable/ViewLegacyTable';
import { ViewProperty } from 'components/Agent/Applications/ApplicationsList/components/ViewPropertyInformation/ViewProperty';
import { PropertyApplicationList } from 'components/Agent/Applications/ApplicationsList/PropertyApplicationList/PropertyApplicationList';
import InviteApplicantModal from 'components/Agent/components/shared/InviteApplicantModal/InviteApplicantModalHandler';
import { RequestVerificationInfoPanel } from 'components/Agent/components/shared/RequestVerificationWrapper/RequestVerificationInfoPanel/RequestVerificationInfoPanel';
import RCButton from 'components/shared/Button/Button';
import ReactSelect from 'components/shared/ReactSelect/ReactSelect';
import Spinner from 'components/shared/Spinner/Spinner';
import { ApplicationRequestValues } from 'constants/applicationConstants';
import { ApplicantStatus, StatusProps } from 'constants/dropdownOptions';
import { FilterOptions } from 'constants/organizationConstants';
import { getFiltersMapping, isSubmittedApplicationStatus } from 'helpers/applicantsHelper';
import { parseResponseErrors, tabTitle } from 'helpers/helper';
import { IsMobile } from 'helpers/IsMobile';
import { useDeviceHeight } from 'hooks/useDeviceHeight';
import { Notification } from 'shared/Notification/Notification';

import styles from './ApplicantsPage.module.scss';
const ApplicantsPage = (): JSX.Element => {
  const [serachParams] = useSearchParams();
  const maxHeight = useDeviceHeight(230);
  const dispatch = useDispatch<AppThunkDispatch>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const activeOrganization = useSelector(selectedOrganization);
  const [isFetchingProperties, setIsFetchingProperties] = useState(true);
  const isMobileView = IsMobile();
  const applicationProperties = useSelector(selectApplicationProperties);
  const invitedApplicants = useSelector(organizationInvites);

  tabTitle(`${activeOrganization.name}`);
  const handleOnChange = useCallback(
    (e: SingleValue<StatusProps>) => {
      navigate(`?option=${e?.name}`);
    },
    [navigate]
  );
  const activeOption = serachParams.get('option') || FilterOptions.ACTIVE;
  const { t } = useTranslation();

  useEffect(() => {
    if (activeOrganization.id) {
      setIsFetchingProperties(true);
      dispatch(resetPropertyApplications());
      dispatch(resetApplicationProperties());
      dispatch(
        getApplicationProperties({
          organizationId: activeOrganization.id,
          applicationStatus: getFiltersMapping(activeOption),
        })
      )
        .unwrap()
        .finally(() => setIsFetchingProperties(false));
    }
  }, [activeOrganization.id, activeOption, dispatch]);

  useEffect(() => {
    if (activeOption === FilterOptions.LEADS && activeOrganization.id) {
      dispatch(resetInvites());
      dispatch(getInvites(activeOrganization.id))
        .unwrap()
        .catch((error) => {
          Notification({ message: parseResponseErrors(error) });
        });
    }
  }, [activeOption, activeOrganization.id, dispatch]);

  const calculatePageState = (): JSX.Element | null => {
    if (activeOption === FilterOptions.LEGACY) {
      return null;
    }

    if (isFetchingProperties) {
      return <Spinner />;
    }

    if (isSubmittedApplicationStatus(activeOption)) {
      if (isEmpty(applicationProperties)) {
        return <EmptyState width={8} setIsModalOpen={setIsModalOpen} />;
      }
    }

    if (
      isEmpty(invitedApplicants.agentSpecificInvites) &&
      isEmpty(invitedApplicants.propertySpecificInvites) &&
      isEmpty(applicationProperties)
    ) {
      return <EmptyState width={8} setIsModalOpen={setIsModalOpen} />;
    }

    return null;
  };
  const checkEmptyState = useCallback((): JSX.Element | null => {
    console.log('checkEmptyState function called');

    return <EmptyState width={8} setIsModalOpen={setIsModalOpen} />;
  }, [setIsModalOpen]);

  return (
    <div
      style={{
        maxHeight: isMobileView ? maxHeight : 'unset',
        paddingBottom: isMobileView ? '20px' : 'unset',
        overflowY: isMobileView ? 'auto' : 'unset',
      }}
      className={classNames(styles.dashContainer)}
    >
      {isMobileView && (
        <div className={styles.FloatingButton}>
          {/* <SendRequestDropDown
            handleModalOpen={() => setIsModalOpen(true)}
            setSelectedInviteType={setSelectedInviteType}
          /> */}
          <RCButton onClick={() => setIsModalOpen(true)}>Send Request</RCButton>
        </div>
      )}
      {!isMobileView && (
        <>
          <div className={styles.dashRow1}>
            <Col sm={6} md={6} lg={6} className={styles.applicantStatus}>
              {t('agent.applicants.property')}
              <div className={styles.verticalLine}></div>
              <ReactSelect
                hasFloatingLabel={true}
                floatingLabel={capitalize(activeOption) || FilterOptions.ACTIVE}
                isSearchable={false}
                options={ApplicantStatus}
                placeholder=""
                dropDownIcon={<ArrowDown />}
                onChange={handleOnChange}
              />
            </Col>
            <Col sm={6} md={6} lg={6} className={styles.applicantButton}>
              {/* <SendRequestDropDown
                handleModalOpen={() => setIsModalOpen(true)}
                setSelectedInviteType={setSelectedInviteType}
              /> */}
              <RCButton onClick={() => setIsModalOpen(true)}>Send Request</RCButton>
            </Col>
            {activeOption === FilterOptions.LEGACY ? (
              <div className={styles.InfoPanel}>
                <RequestVerificationInfoPanel
                  infoDescription={t('agent.applicants.legacy')}
                  isLinkDisabled={true}
                  isIconDisabled={true}
                  className={styles.InfoWidth}
                />
              </div>
            ) : (
              <div></div>
            )}
          </div>

          <div className={styles.horizontalLine}></div>
        </>
      )}
      {isSubmittedApplicationStatus(activeOption) && !isEmpty(applicationProperties) && (
        <div className={styles.PropertyApplicationListContainer}>
          <PropertyApplicationList propertyList={applicationProperties} checkEmptyState={checkEmptyState} />
        </div>
      )}
      {activeOption === FilterOptions.LEADS && (
        <div>
          <div className={styles.InvitedContainer}>
            {invitedApplicants?.agentSpecificInvites && (
              <ViewGeneralInvite
                totalActive={invitedApplicants.agentSpecificInvites.length}
                invites={invitedApplicants.agentSpecificInvites}
              />
            )}
            {invitedApplicants?.propertySpecificInvites &&
              invitedApplicants?.propertySpecificInvites.map((invite, index) => {
                const propertyExists = applicationProperties?.find((p) => p.id === invite.property.propertyId);

                if (!propertyExists) {
                  return (
                    <ViewProperty
                      invite={invite}
                      key={`property-specific-invite-${index}`}
                      property={invite.property}
                    />
                  );
                }

                return null;
              })}
          </div>
          {!isEmpty(applicationProperties) && (
            <div className={styles.PropertyApplicationListContainer}>
              <PropertyApplicationList propertyList={applicationProperties} checkEmptyState={checkEmptyState} />
            </div>
          )}
        </div>
      )}
      {activeOption === FilterOptions.LEGACY && (
        <div className={styles.LegacyTableContainer}>
          <ViewLegacyTable />
        </div>
      )}

      {calculatePageState()}

      {isModalOpen && (
        <InviteApplicantModal
          inviteType={ApplicationRequestValues.APPLICATION_AND_REPORTS}
          isModalOpen={isModalOpen}
          onHide={() => setIsModalOpen(false)}
        />
      )}
    </div>
  );
};

export default ApplicantsPage;
