//TODO: Temporarily disabled CompleteReviewDropdown component feature and will re-enable later.
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { ReactComponent as ArrowRight } from 'assets/svgs/ArrowForward.svg';
import { ReactComponent as DocumentIcon } from 'assets/svgs/DocumentIcon.svg';
import { ReactComponent as PaperClip } from 'assets/svgs/paperclip.svg';
import AgentPills from 'components/shared/AgentPills/AgentPills';
import { PreviewFile } from 'components/shared/DropZone/Dropzone';
import { RenterPresentEmployments } from 'shared/types/renterTypes';
import { determineDocumentStatusPill, ValidaStatus } from 'shared/utils/document';

// import { CompleteReviewDropdown } from '../../shared/CompleteReviewDropdown/CompleteReviewDropdown';
import DocumentItem from '../../shared/Documents/DocumentItem/DocumentItem';

import styles from './DocumentDigest.module.scss';

interface Props {
  employment: RenterPresentEmployments;
}

export const DocumentDigest = ({ employment }: Props): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [difference, setDifference] = useState(0);
  const [selectedDocument, setSelectedDocument] = useState<undefined | PreviewFile>(
    employment.documents ? employment.documents[0] : undefined
  );
  const { t } = useTranslation();

  return (
    <div className={styles.Container}>
      <div className={styles.HeadingRow}>
        <div className={styles.Heading}>{t('application.incomeReport.documentDigest')}</div>
        {/* <CompleteReviewDropdown /> */}
      </div>
      <div className={styles.IncomeRow}>
        <div className={styles.IncomeItem}>
          <div className={styles.ItemHeading}>{t('application.incomeReport.documentDigest.statedMonthlyIncome')}</div>
          <div className={styles.ItemText}>{`$${employment.monthlyGrossIncome}`}</div>
        </div>
        <div className={styles.IncomeItem}>
          <div className={styles.ItemHeading}>
            {t('application.incomeReport.documentDigest.documentValidatedIncome')}
          </div>
          <div className={styles.ItemText}>
            <span>$</span>
            <input
              className={styles.DocumentInput}
              type="number"
              onChange={(event) =>
                setDifference(
                  employment.monthlyGrossIncome ? employment.monthlyGrossIncome - Number(event.target.value) : 0
                )
              }
              onWheel={(event) => event.currentTarget.blur()}
            />
          </div>
        </div>
        <div className={styles.IncomeItem}>
          <div className={styles.ItemHeading}>{t('application.incomeReport.documentDigest.difference')}</div>
          <div className={styles.ItemText}>{`$${difference}`}</div>
        </div>
      </div>
      <div className={styles.DocumentSection}>
        <div className={styles.DocumentHeadingRow}>
          <ArrowRight
            className={classNames(styles.ArrowIcon, {
              [styles.RotateDown]: isExpanded,
              [styles.RotateUp]: !isExpanded,
            })}
            onClick={() => setIsExpanded(!isExpanded)}
          />
          <div className={styles.DocText}>{t('application.incomeReport.documents')}</div>
          <AgentPills pillsData={[determineDocumentStatusPill(employment.documents)]} />
        </div>
        <div className={styles.PayStubRow}>
          <div>{t('renter.incomeInformation.employed.paystubs')}</div>
          <PaperClip />
        </div>
        <div className={styles.AttachmentRow}>
          {employment?.documents?.map((document, index) => (
            <DocumentItem
              onClick={() => setSelectedDocument(document)}
              key={`attachment-${document.fileLink}`}
              documentName={document.fileName}
              documentLink={document.fileLink}
              icon={<DocumentIcon />}
              index={index + 1}
              validaStatus={document.validaStatus as ValidaStatus}
            />
          ))}
        </div>
      </div>
      {isExpanded && (
        <iframe
          title="document"
          src={selectedDocument?.forgedFileLink ?? selectedDocument?.fileLink}
          width={'100%'}
          height="800px"
        />
      )}
    </div>
  );
};
