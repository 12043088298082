import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { t } from 'i18next';

import { ReactComponent as NotificationIcon } from 'assets/svgs/notification_importantPinkBellIcon.svg';
import { ValidaStatus } from 'shared/utils/document';

import styles from './DocumentItem.module.scss';
type DocumentBoxType = {
  documentName?: string;
  documentLink?: string;
  icon?: ReactNode;
  index?: number;
  customLabel?: string;
  shouldShowCount?: boolean;
  validaStatus?: ValidaStatus;
  onClick?: () => void;
};

const DocumentItem = ({
  documentName,
  documentLink,
  icon,
  index = 0,
  customLabel,
  shouldShowCount = true,
  onClick,
  validaStatus,
}: DocumentBoxType): JSX.Element => (
  <div className={styles.Document} key={`supporting-doc-${index}`} onClick={onClick} role="presentation">
    <div className={styles.BellRow}>
      <span className={styles.Label}>
        {customLabel ?? t('agent.applicants.renterResume.sourceOfIncome.incomeReport.attachment')}
        {shouldShowCount ? index : null}
      </span>
      {validaStatus === ValidaStatus.POTENTIAL_RISK && <NotificationIcon />}
    </div>
    <div className={styles.Info}>
      <span>{icon}</span>
      {documentLink ? (
        <Link to={documentLink} className={styles.FileName} target="_blank" rel="noopener">
          <span>{documentName}</span>
        </Link>
      ) : (
        <span className={styles.FileName}>{documentName}</span>
      )}
    </div>
  </div>
);

export default DocumentItem;
