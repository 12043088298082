import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { isEmpty } from 'lodash-es';
import { selectRenterProfileInformation } from 'redux/selectors/renterSelector';
import { setSecurityQuestionsRenter } from 'redux/slices/authSlice';
import { confirmRenterApplication, getAgentDetails, getRenterProfileInformation } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';

import { RequestVerificationInfoPanel } from 'components/Agent/components/shared/RequestVerificationWrapper';
import indexStyles from 'components/Renter/IdentityVerification/IdentityVerification.module.scss';
import { PersonalProfile } from 'components/Renter/IdentityVerification/RenterDetails';
import { PresentAddress } from 'components/Renter/IdentityVerification/RenterDetails/AddressHistory/components';
import RCButton from 'components/shared/Button/Button';
import { OTPModal } from 'components/shared/OTPModal/OTPModal';
import { findApplication } from 'helpers/agentHelper';
import { tabTitle } from 'helpers/helper';
import { renterResendOtpRequest, submitRenterOtpRequest } from 'helpers/renterHelper';
import { useDeviceHeight } from 'hooks/useDeviceHeight';
import { useDeviceInfoCollector } from 'hooks/useDeviceInfoCollector';
import { Notification } from 'shared/Notification/Notification';
import { renterRoutes } from 'shared/routes';

import RenterContainer from '../RenterContainer';

import AgentDetails from './AgentDetails/AgentDetails';

import styles from './RenterReport.module.scss';
export const RenterReport = (): JSX.Element => {
  tabTitle('Your Application | Report');
  const { t } = useTranslation();
  const { jscInputRef, getDeviceInfo } = useDeviceInfoCollector();
  const { applicationId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppThunkDispatch>();
  const renterDetails = useSelector(selectRenterProfileInformation);
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const [initialOtpCode, setInitialOtpCode] = useState('');
  const [hasOtpExpired, setHasOtpExpired] = useState(false);
  const maxHeight = useDeviceHeight(340);
  const [isOtpRequestLaoding, setIsOtpRequestLoading] = useState(false);
  const isButtonDisabled = useMemo(
    (): boolean =>
      isEmpty(renterDetails.renterPhoneNumbers) ||
      isEmpty(renterDetails.addresses) ||
      isEmpty(renterDetails.presentEmployments) ||
      !renterDetails.firstName ||
      !renterDetails.lastName ||
      !renterDetails.birthDate ||
      !renterDetails.email ||
      !renterDetails.ssnTailDigits,
    [renterDetails]
  );
  const currentApplication = useMemo(
    () => findApplication(renterDetails?.applications, Number(applicationId)),
    [applicationId, renterDetails]
  );
  const onConfirmClickHandler = useCallback(() => {
    if (!renterDetails?.isPaymentSucceeded) {
      navigate(renterRoutes.generateRenterPaymentMethod(applicationId));
    } else if (!currentApplication?.isConfirmed) {
      const body = getDeviceInfo();

      if (!body) {
        return;
      }

      dispatch(confirmRenterApplication({ applicationId: Number(applicationId), body }))
        .unwrap()
        .then((res) => {
          if (res?.success) {
            if (res?.kiqEnabled) {
              dispatch(setSecurityQuestionsRenter(res?.kba?.questionSet));
              navigate(renterRoutes.generateRenterSecurityQuestionnaire(applicationId));

              return;
            }

            if (res?.otpEnabled) {
              setInitialOtpCode(
                res?.crossCoreFullResponse?.clientResponsePayload?.decisionElements?.[0]?.decisions?.[0]?.value
              );
              setIsOtpModalOpen(true);

              return;
            }
          } else {
            res?.error && Notification({ message: res?.error?.Message });
          }

          dispatch(getRenterProfileInformation());
          navigate(renterRoutes.applications);
        });
    } else {
      navigate(renterRoutes.applications);
    }
  }, [
    applicationId,
    currentApplication?.isConfirmed,
    dispatch,
    getDeviceInfo,
    navigate,
    renterDetails?.isPaymentSucceeded,
  ]);
  const onSubmitClickHandler = useCallback(
    (code: string) => {
      submitRenterOtpRequest({
        code: code,
        setHasOtpExpired: setHasOtpExpired,
        setIsOtpRequestLoading: setIsOtpRequestLoading,
        dispatch: dispatch,
        navigate: navigate,
        applicationId: applicationId || '',
        setInitialOtpCode: setInitialOtpCode,
      });
    },
    [applicationId, dispatch, navigate]
  );
  const onResendOtpClickHandler = (): void => {
    renterResendOtpRequest({ setInitialOtpCode: setInitialOtpCode, setHasOtpExpired: setHasOtpExpired });
  };

  useEffect(() => {
    if (applicationId) {
      dispatch(getAgentDetails({ applicationId: Number(applicationId) }));
    }
  }, [dispatch, applicationId]);

  useEffect(() => {
    if (window.adx) {
      window.adx.initiate();
    }
  }, []);

  return (
    <RenterContainer>
      <div className={styles.Container}>
        <input type="hidden" name="user_prefs2" id="user_prefs2" ref={jscInputRef} />
        <div className={styles.HeaderContainer}>
          <div className={styles.TitileContainer}>{t('renter.report.title')}</div>
        </div>
        <section className={indexStyles.renterBody}>
          <div
            className={styles.confirmAddressHistoryContainer}
            style={{ paddingRight: '12px', maxHeight, paddingBottom: '20px', overflowY: 'auto' }}
          >
            <RequestVerificationInfoPanel
              infoDescription={t('renter.report.infoPanel')}
              isLinkDisabled
              className={styles.InfoPanel}
            />
            <div className={classNames(styles.SubContainer, 'documentRow')}>
              <div className={indexStyles.container}>
                <PersonalProfile isReportPage />
              </div>
              <PresentAddress isReportPage />
              <AgentDetails />
              <span className={classNames(styles.InfoPanel, styles.Description)}>{t('renter.report.description')}</span>
              <div className={styles.RenterButtonContainer}>
                <RCButton
                  disabled={isOtpModalOpen || isButtonDisabled}
                  variant="outline"
                  className={styles.RenterButton}
                  onClick={onConfirmClickHandler}
                >
                  {t('renter.report.buttonTitle')}
                </RCButton>
              </div>
            </div>
            {isOtpModalOpen && (
              <OTPModal
                isLoading={isOtpRequestLaoding}
                isExpired={hasOtpExpired}
                onSubmitHandler={onSubmitClickHandler}
                initialOtpValue={initialOtpCode}
                onResendOtpClickHandler={onResendOtpClickHandler}
              />
            )}
          </div>
        </section>
      </div>
    </RenterContainer>
  );
};
