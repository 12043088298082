import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';

import RCButton from 'components/shared/Button/Button';
import CustomDropzone, { CustomPreviewFile } from 'components/shared/DropZone/CustomDropzone/CustomDropzone';
import ImageProgressBox from 'components/shared/ImageProgressBox/ImageProgressBox';
import { renterRoutes } from 'shared/routes';

import RenterContainer from '../components/RenterContainer';
import RenterIconInfoContainer from '../components/RenterIconInfoContainer';

import indexStyles from './../RenterIndex.module.scss';
import styles from './NoCreditHistory.module.scss';
const TOTAL_OPTIONS = 3;
const NoCreditHistory = (): JSX.Element => {
  const { t } = useTranslation();
  const { applicationId } = useParams();
  const [acceptedFiles, setAcceptedFiles] = useState<CustomPreviewFile[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<boolean[]>(Array(TOTAL_OPTIONS).fill(false));
  const handleOptionOnlick = useCallback((option: number): void => {
    const tempArray = Array(TOTAL_OPTIONS).fill(false);

    tempArray[option] = true;
    setSelectedOptions(tempArray);
  }, []);
  const navigate = useNavigate();

  return (
    <RenterContainer>
      <section className={indexStyles.renterBody}>
        {/* icons list */}
        <div className={indexStyles.contentContainer}>
          <RenterIconInfoContainer />
          <div className={styles.scrollContainer}>
            <div className={indexStyles.renterContentContainer}>
              <h2 className={indexStyles.renterH2}>{t('renter.noCreditHistory.IfYouDontHaveCreditHistory')}</h2>
              <div className={indexStyles.renterButtonContainer}>
                <RCButton
                  onClick={() => handleOptionOnlick(0)}
                  className={classNames(indexStyles.renterButton, styles.renterCreditButton, {
                    [indexStyles.dullButton]: !selectedOptions[0],
                  })}
                  variant="outline"
                >
                  {t('renter.noCreditHistory.IAmAnInternationalStudent')}
                </RCButton>
              </div>
              <div className={indexStyles.renterButtonContainer}>
                <RCButton
                  onClick={() => handleOptionOnlick(1)}
                  className={classNames(indexStyles.renterButton, styles.renterCreditButton, {
                    [indexStyles.dullButton]: !selectedOptions[1],
                  })}
                  variant="outline"
                >
                  {t('renter.noCreditHistory.IAmInTheUSOnWorkVisa')}
                </RCButton>
              </div>
            </div>
            <div className={indexStyles.renterButtonContainer}>
              <RCButton
                onClick={() => handleOptionOnlick(2)}
                className={classNames(indexStyles.renterButton, styles.renterCreditButton, {
                  [indexStyles.dullButton]: !selectedOptions[2],
                })}
                variant="outline"
              >
                {t('renter.noCreditHistory.other')}
              </RCButton>
            </div>
            <div className={styles.dropzoneContainer}>
              <CustomDropzone
                acceptedFiles={acceptedFiles}
                setAcceptedFiles={setAcceptedFiles}
                renderInDropzone={true}
              />
            </div>
            <div className={styles.exampleImageContainer}>
              <ImageProgressBox
                label={t('renter.fileUploadingState.uploadedFile')}
                fileName="Identification Card.png"
                onRemoveHandler={() => {
                  console.log('I was clicked');
                }}
                isImageUploaded={true}
              />
            </div>
            <div className={indexStyles.renterButtonContainer}>
              <RCButton
                className={classNames(indexStyles.renterButton, styles.renterCreditButton)}
                variant="outline"
                onClick={() => navigate(renterRoutes.generateGuarantorInformation(`${applicationId}`))}
              >
                {t('renter.noCreditHistory.SaveAndContinue')}
              </RCButton>
            </div>
          </div>
        </div>
      </section>
    </RenterContainer>
  );
};

export default NoCreditHistory;
