import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { addDays, differenceInDays, format } from 'date-fns';
import { selectRenterCreditProfile } from 'redux/selectors/agentSelector';
import { selectedOrganization } from 'redux/selectors/organizationSelector';
import { getRenterExperianReport } from 'redux/slices/agentSlice';
import { getAllProperties } from 'redux/slices/propertySlice';
import { AppThunkDispatch } from 'redux/store';

import { ReactComponent as ArrowIcon } from 'assets/svgs/BlackDownArrow.svg';
import { ReactComponent as LoadingIcon } from 'assets/svgs/BlackLoadingIcon.svg';
import { ReactComponent as ExperianIcon } from 'assets/svgs/ExperianLogoFull.svg';
import InviteApplicantModal from 'components/Agent/components/shared/InviteApplicantModal/InviteApplicantModalHandler';
import AgentPills, { PillTypes } from 'components/shared/AgentPills/AgentPills';
import RCButton from 'components/shared/Button/Button';
import { ApplicationRequestValues } from 'constants/applicationConstants';
import { dateFormatFrontend } from 'constants/calendarConstants';
import { ReportErrorTypes, reportExpireyErrorCode } from 'constants/reportConstants';
import { getRemainingDaysPillType } from 'helpers/reportsHelper';
import { PropertyType } from 'shared/types/propertType';

import { CreditReport } from './components/CreditReport/CreditReport';
import { CriminalReport } from './components/CriminalReport/CriminalReport';
import { HousingCourtRecords } from './components/HousingCourtRecords/HousingCourtRecords';
import { ReportStatusLoader } from './components/ReportStatusLoader/ReportStatusLoader';

import styles from './RenterCreditAndBackground.module.scss';

const RenterCreditAndBackground = (): JSX.Element => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const { t } = useTranslation();
  const { applicationId } = useParams();
  const currentOrganization = useSelector(selectedOrganization);
  const [isReportLoaded, setIsReportLoaded] = useState(false);
  const [isReportExpired, setIsReportExpired] = useState(false);
  const [isReportError, setIsReportError] = useState(false);
  const renterCreditProfile = useSelector(selectRenterCreditProfile);
  const [isCreditReportError, setIsCreditReportError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const getDate30DaysAhead = useCallback((date: string): string => {
    if (!date) {
      return '—';
    }

    return format(addDays(new Date(date), 30), dateFormatFrontend);
  }, []);
  const getRemainingDays = useMemo((): number | string => {
    if (!renterCreditProfile.report || !renterCreditProfile.success) {
      return '–';
    }

    const difference =
      differenceInDays(
        new Date(getDate30DaysAhead(renterCreditProfile?.report?.Header?.ReportDate ?? '')),
        new Date()
      ) + 1;

    return difference > 0 ? difference : 0;
  }, [getDate30DaysAhead, renterCreditProfile]);
  const getReportStatusPill = (): PillTypes => {
    if (isReportExpired) {
      return PillTypes.SECONDARY;
    }

    if (isReportError || isCreditReportError) {
      return PillTypes.DANGER;
    }

    return PillTypes.PRIMARY_CYAN_LIGHT;
  };
  const getReportStatusHeading = useMemo((): string => {
    if (isReportExpired) {
      return t('agent.creditReport.expiredReport');
    }

    if (isReportError || isCreditReportError) {
      return t('application.warning');
    }

    return t('agent.creditReport.inProgressReport');
  }, [isCreditReportError, isReportError, isReportExpired, t]);
  const getReportStatusTexts = useMemo((): string[] => {
    if (isReportExpired) {
      return [t('agent.creditBackground.expiredText'), ` ${t('agent.properties.learnMore')}`];
    }

    if (isReportError || isCreditReportError) {
      return [t('agent.creditBackground.warningText'), ` ${t('rental.historyInformation.contactSupport')}`];
    }

    return [t('agent.creditBackground.inProgressText'), ` ${t('agent.properties.learnMore')}`];
  }, [isCreditReportError, isReportError, isReportExpired, t]);
  const hasReportErrors = useMemo(
    (): boolean => isReportError || isReportExpired || !isReportLoaded,
    [isReportError, isReportExpired, isReportLoaded]
  );
  const areThereAnyErrors = useMemo(
    (): boolean => hasReportErrors || isCreditReportError,
    [hasReportErrors, isCreditReportError]
  );

  useEffect(() => {
    if (currentOrganization.id && applicationId && !isReportLoaded) {
      dispatch(
        getRenterExperianReport({ organizationId: currentOrganization.id, applicationId: Number(applicationId) })
      )
        .unwrap()
        .then((res) => {
          if (!res?.success) {
            setIsReportError(true);

            return;
          }
        })
        .catch((errorCode) => {
          setIsReportExpired(errorCode.message === reportExpireyErrorCode);
          setIsReportError(true);
        })
        .finally(() => {
          setIsReportLoaded(true);
        });
    }
  }, [applicationId, currentOrganization, dispatch, isReportLoaded]);
  const handleOpenModal = useCallback(() => {
    dispatch(
      getAllProperties({
        propertyId: undefined,
        organizationId: currentOrganization.id,
        propertyStatus: PropertyType.PUBLISHED,
      })
    )
      .unwrap()
      .then(() => setIsModalOpen(true));
  }, [currentOrganization.id, dispatch]);

  useEffect(() => {
    if (renterCreditProfile?.success === false) {
      setIsCreditReportError(true);
    }
  }, [renterCreditProfile]);

  return (
    <div className={styles.Container}>
      <div className={styles.ReportValidity}>
        <div className={styles.RemainingDays}>
          <div className={styles.SpaceProvider} />
          <AgentPills
            pillContainerClassName={styles.PillContainer}
            pillsData={[
              {
                heading: `${isReportExpired ? 0 : getRemainingDays} ${t('agent.creditReport.days')}`,
                type: getRemainingDaysPillType(isReportExpired ? 0 : getRemainingDays),
              },
            ]}
          />
        </div>
        <div className={styles.CreditBackgroundHeader}>
          <div className={styles.ExperianLogoContainer}>
            <div className={styles.LogoWithButtons}>
              <div className={styles.ExperianLogo}>
                <ExperianIcon />
                {areThereAnyErrors && (
                  <AgentPills
                    pillContainerClassName={styles.PillContainer}
                    pillsData={[
                      {
                        heading: getReportStatusHeading,
                        type: getReportStatusPill(),
                      },
                    ]}
                  />
                )}
              </div>
              {(isReportExpired || !isReportLoaded) && (
                <div className={styles.ButtonsContainer}>
                  {isReportExpired && (
                    <>
                      <RCButton className={styles.ReportButton} variant="outline">
                        {t('agent.creditBackground.savedFiles')}
                      </RCButton>
                      <RCButton onClick={handleOpenModal} className={styles.ReportButton} variant="outline">
                        {t('agent.creditBackground.requestNew')}
                      </RCButton>
                    </>
                  )}
                  {!isReportLoaded && (
                    <RCButton className={styles.ReportButton} variant="outline">
                      <LoadingIcon />
                      {t('agent.creditBackground.reloadButton')}
                    </RCButton>
                  )}
                </div>
              )}
            </div>
            {areThereAnyErrors && (
              <div className={styles.ReportStatusText}>
                {getReportStatusTexts[0]}
                <Link to="#" className={styles.LinkText}>
                  {getReportStatusTexts[1]}
                </Link>
              </div>
            )}
          </div>
          <div className={styles.ValidityDates}>
            <div className={styles.DateContainer}>
              <div className={styles.Label}>{t('agent.creditReport.returned')}</div>
              <div
                className={classNames(styles.Date, {
                  [styles.NoDate]: !renterCreditProfile?.report?.Header?.ReportDate,
                })}
              >
                {renterCreditProfile?.report?.Header?.ReportDate ?? '—'}
              </div>
            </div>
            <div className={styles.DateContainer}>
              <div className={styles.Label}>{t('agent.creditReport.expires')}</div>
              <div
                className={classNames(styles.Date, {
                  [styles.NoDate]: !renterCreditProfile?.report?.Header?.ReportDate,
                })}
              >
                {getDate30DaysAhead(renterCreditProfile?.report?.Header?.ReportDate ?? '')}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.CreditReportContainer}>
        {areThereAnyErrors && (
          <>
            <div className={styles.CreditIconContainer}>
              <ArrowIcon className={classNames(styles.ArrowIcon)} />
              <div className={styles.Heading}>{t('agent.creditReport.creditReport')}</div>
            </div>
            {!isReportLoaded && ( //Report is Loading
              <div className={styles.ErrorContainer}>
                <ReportStatusLoader errorType={ReportErrorTypes.IN_PROGRESS} />
              </div>
            )}
            {isReportExpired &&
              isReportLoaded && ( //Report is Expired
                <div className={styles.ErrorContainer}>
                  <ReportStatusLoader errorType={ReportErrorTypes.EXPIRED} />
                </div>
              )}
            {isReportError &&
              !isReportExpired &&
              isReportLoaded && ( //Report fetching Had Errors
                <div className={styles.ErrorContainer}>
                  <ReportStatusLoader errorType={ReportErrorTypes.ERROR} />
                </div>
              )}
            {isCreditReportError && ( //Credit report data fetching failed
              <div className={styles.ErrorContainer}>
                <ReportStatusLoader
                  errorCode={renterCreditProfile.error.Code}
                  errorMessage={renterCreditProfile.error.Message ?? ''}
                  errorType={ReportErrorTypes.ERROR}
                />
              </div>
            )}
          </>
        )}
        {!areThereAnyErrors && <CreditReport />}
      </div>
      <div className={styles.CreditReportContainer}>
        {hasReportErrors && (
          <>
            <div className={styles.CreditIconContainer}>
              <ArrowIcon className={styles.ArrowIcon} />
              <div className={classNames(styles.Heading, styles.DisabledState)}>
                {t('agent.creditReport.criminalRecords')}
              </div>
            </div>
            <div className={styles.ErrorContainer}>
              <ReportStatusLoader />
            </div>
          </>
        )}

        {!hasReportErrors && <CriminalReport />}
      </div>
      <div className={styles.CreditReportContainer}>
        {hasReportErrors && (
          <>
            <div className={styles.CreditIconContainer}>
              <ArrowIcon className={classNames(styles.ArrowIcon)} />
              <div className={styles.Heading}>{t('agent.creditReport.housingCourtRecords')}</div>
            </div>

            <div className={styles.ErrorContainer}>
              <ReportStatusLoader />
            </div>
          </>
        )}
        {!hasReportErrors && <HousingCourtRecords />}
      </div>
      {isReportExpired && isModalOpen && (
        <InviteApplicantModal
          inviteType={ApplicationRequestValues.REPORTS_ONLY}
          isModalOpen={isModalOpen}
          onHide={() => setIsModalOpen(false)}
        />
      )}
    </div>
  );
};

export default RenterCreditAndBackground;
